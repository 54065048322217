import React from "react";
import jsPDF from "jspdf";
import { renderToString } from "react-dom/server";
import { useForm } from "react-hook-form";
import * as Y from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { useGetCall } from "../../lib/api/get";
import { useCreateCall } from "../../lib/api/create";
import httpParams from "../../lib/httpParams";
import { E500 } from "../../components/e500";
import { Form } from "../../components/form";

export const AttendanceModalChild = ({
	appVariables,
	setOpenActivity,
	activityAttenInd,
	pdfSchema,
	pdfSchemaSettings,
	schema,
	selectedApplData,
	primaryColor,
	secondaryColor,
	favicon,
}) => {
	const params = httpParams.adminOrgParams(appVariables);
	const createCallMutation = useCreateCall();
	const [isChildAdd, setIsChildAdd] = React.useState(false);
	const [isFileAdd, setIsFileAdd] = React.useState(false);
	const [activeActivityId, setActiveActivityId] = React.useState(null);
	const getData = useGetCall(
		appVariables.REACT_APP_API_URL,
		"activity",
		params + "&attendanceId=" + selectedApplData[activityAttenInd].attendanceId
	);

	const validation = Y.object().shape({
		document: Y.mixed().required("Choose a document to upload!"),
	});

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		mode: "onSubmit",
		resolver: yupResolver(validation),
	});

	const onFormSubmit = async (data) => {
		let formData = new FormData();
		formData.append("file", data.document[0]);
		formData.append("activityId", activeActivityId);
		formData.append("activityDetailLabel", data.activityDetailLabel);
		formData.append("activityDetailNotes", data.activityDetailNotes);
		await createCallMutation.mutateAsync({
			url: appVariables.REACT_APP_API_URL,
			apiEndpoint: "activityDetail/image",
			parentAPI: "activity",
			data: formData,
			messageTitle: "Files",
			message: "File uploaded successfully!",
			params: params,
		});
	};

	if (getData.isLoading) {
		return <div>Loading...</div>;
	}

	const startActivityFile = (row) => {
		setActiveActivityId(row.activityId);
		setIsFileAdd(true);
	};

	const closeActivityFile = () => {
		setActiveActivityId(null);
		setIsFileAdd(false);
	};

	try {
		return (
			<div
				className={`modal fade bd-example-modal-lg show`}
				style={{ display: "block", backgroundColor: "rgba(55,55,55,0.3)" }}
			>
				<div className="modal-dialog modal-dialog-centered modal-md">
					<div className="modal-content">
						<div className="modal-body">
							<button
								type="button"
								className="close"
								data-dismiss="modal"
								aria-label="Close"
								onClick={() => setOpenActivity(false)}
							>
								<span aria-hidden="true">×</span>
							</button>
							<div className="row">
								<div className="col-12">
									<div className="modal-bg">
										{schema.isRowForm && (
											<>
												<div className="container">
													<h3 className="font-light pt-2">
														{schema.rowForm.formTitle}
													</h3>
													<button
														className="btn btn-primary btn-sm"
														onClick={() => setIsChildAdd(true)}
													>
														<i className="fa fa-plus"></i>
													</button>
													<div className="mt-3">
														{isFileAdd && (
															<form
																onSubmit={handleSubmit(onFormSubmit)}
																className="row"
															>
																<div className="col-4">
																	<input
																		type="text"
																		{...register("activityDetailDescription")}
																		className="form-control"
																	/>
																</div>
																<div className="col-4">
																	<input
																		type="text"
																		{...register("activityDetailNotes")}
																		className="form-control"
																	/>
																</div>
																<div className="col-4">
																	<input
																		type="file"
																		{...register("document")}
																		className="form-control"
																	/>
																</div>
																<div className="col-12 mt-2">
																	<button
																		type="submit"
																		className="btn btn-primary"
																	>
																		{createCallMutation.isLoading ? (
																			<>
																				<span
																					className="spinner-border spinner-border-sm"
																					role="status"
																					aria-hidden="true"
																				></span>{" "}
																				Uploading...
																			</>
																		) : (
																			<>Upload</>
																		)}
																	</button>{" "}
																	<button
																		type="button"
																		onClick={() => closeActivityFile()}
																		className="btn btn-secondary"
																	>
																		Close
																	</button>
																</div>
															</form>
														)}
														{isChildAdd && (
															<Form
																key="addChildRow"
																formSchema={schema.rowForm.create}
																parentId={
																	getData.data.formData[0][
																		schema.rowForm.create.parentId
																	]
																}
																crud={schema.rowForm.crud}
																appVariables={appVariables}
																isRowForm={true}
																isAdd={true}
																isChildAdd={isChildAdd}
																setIsChildAdd={setIsChildAdd}
																primaryColor={primaryColor}
																secondaryColor={secondaryColor}
																favicon={favicon}
															/>
														)}
													</div>
													<hr />
													<div
														className="cScrollBar mb-5"
														style={{ maxHeight: "15rem", overflowY: "initial" }}
													>
														{getData.data.formData[0].rowFormData.map(
															(row, rowIndex) => (
																<React.Fragment key={`${rowIndex}`}>
																	<Form
																		key={`${rowIndex}`}
																		defaultData={row}
																		isRowForm={true}
																		formSchema={schema.rowForm.update}
																		parentId={
																			getData.data.formData[0][
																				schema.rowForm.update.parentId
																			]
																		}
																		crud={schema.rowForm.crud}
																		appVariables={appVariables}
																		primaryColor={primaryColor}
																		secondaryColor={secondaryColor}
																		favicon={favicon}
																	/>
																	<button
																		className="btn btn-primary btn-sm"
																		onClick={() => startActivityFile(row)}
																	>
																		<i className="fa fa-plus"></i> Upload Media
																	</button>
																	<hr />
																</React.Fragment>
															)
														)}
													</div>
												</div>
											</>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	} catch (err) {
		return (
			<E500
				primaryColor={primaryColor}
				secondaryColor={secondaryColor}
				favicon={favicon}
				error={err}
			/>
		);
	}
};
