import React from "react";
import { Chart } from "react-google-charts";

export const Charts = ({ reportData }) => {
	return (
		<div className={reportData.colSize}>
			<h6>{reportData.name}</h6>
			<br />
			<Chart
				chartType={reportData.chartType}
				width="100%"
				height="400px"
				data={reportData.reportData.data}
				options={reportData.reportData.options}
			/>
		</div>
	);
};
