import React from "react";
import { E500 } from "../e500";

export const TextArea = ({
	field,
	errors,
	register,
	isAdd,
	defaultData,
	primaryColor,
	secondaryColor,
	favicon,
}) => {
	try {
		return (
			<div
				className={`form-group col-md-${field.size}`}
				style={{ display: field.hidden ? "none" : "block" }}
			>
				<label htmlFor="inputEmail4">{field.placeholder}</label>
				{isAdd ? (
					<>
						<textarea
							type={field.type}
							className={`form-control ${errors[field.name] && "is-invalid"}`}
							placeholder={field.placeholder}
							{...register(field.name)}
						></textarea>
						{errors[field.name] && (
							<div className="invalid-feedback">
								{errors[field.name].message}
							</div>
						)}
					</>
				) : (
					<>
						<textarea
							type={field.type}
							className={`form-control ${errors[field.name] && "is-invalid"}`}
							placeholder={field.placeholder}
							{...register(field.name)}
						>
							{defaultData[field.name]}
						</textarea>
						{errors[field.name] && (
							<div className="invalid-feedback">
								{errors[field.name].message}
							</div>
						)}
					</>
				)}
			</div>
		);
	} catch (err) {
		return (
			<E500
				primaryColor={primaryColor}
				secondaryColor={secondaryColor}
				favicon={favicon}
				error={err}
			/>
		);
	}
};
