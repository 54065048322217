import React from "react";

import { mainUILoad, closeModal } from "../lib/uihelper";
import logo from "../assets/images/logo.png";
import "../assets/css/bootstrap.min.css";
import "../assets/css/icons.css";
import "../assets/css/flag-icon.min.css";
import "../assets/css/style.css";
import "../assets/css/custom.css";
import { ModalLogin } from "./modal";
import { Roles } from "../modules/roles";
import { Link } from "react-router-dom";
import { E500 } from "./e500";
export const E404 = (props) => {
	mainUILoad(props.primaryColor, props.secondaryColor, props.favicon);

	const [userRoles, setUserRoles] = React.useState(false);
	const [loginModal, setLoginModal] = React.useState(false);

	try {
		return (
			<div>
				<Roles userRoles={userRoles} setUserRoles={setUserRoles} />
				<div id="containerbar" className="containerbar authenticate-bg">
					<div className="container">
						<div className="auth-box error-box">
							<div className="row no-gutters align-items-center justify-content-center">
								<div className="col-md-8 col-lg-6">
									<div className="text-center">
										<img
											src={props.appVariables.REACT_APP_THEME_LOGO}
											className="img-fluid error-logo"
											alt="logo"
										/>
										<img
											src={require("../assets/images/error/404.svg").default}
											className="img-fluid error-image"
											alt={404}
										/>
										<h4 className="error-subtitle mb-4">
											Oops! Page not Found
										</h4>
										<p className="mb-4">
											We did not find the page you are looking for. Please
											return to previous page or visit home page.{" "}
										</p>
										<Link to="/" className="btn btn-primary font-16">
											<i className="feather icon-home mr-2" /> Go back to Home
										</Link>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				{loginModal && (
					<ModalLogin closeModal={closeModal} setLoginModal={setLoginModal} />
				)}
			</div>
		);
	} catch (err) {
		return (
			<E500
				primaryColor={props.primaryColor}
				secondaryColor={props.secondaryColor}
				favicon={props.favicon}
				error={err}
			/>
		);
	}
};
