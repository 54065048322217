import React from "react";
import jsPDF from "jspdf";

import httpParams from "../../lib/httpParams";
import { Pagination } from "../pagination";
import { Form } from "../../components/form";
import { E500 } from "../../components/e500";
import { captureFinger } from "../../lib/FingerPrintDevice/fpFuncs";
import { ModalFileManager } from "./modalFileManager";
import { DocManagerForm } from "./docManagerForm";

var quality = 60; //(1 to 100) (recommanded minimum 55)
var timeout = 10; // seconds (minimum=10(recommanded), maximum=60, unlimited=0 )

export const BasicForm = ({
	setMainCrudAdd,
	mainCrudAdd,
	closeAddMainCrud,
	modSchema,
	currentPage,
	totalCount,
	siblingCount,
	pageSize,
	filteredData,
	handlePage,
	getData,
	appVariables,
	uiHelper,
	setAllData,
	allData,
	primaryColor,
	secondaryColor,
	favicon,
	authData,
}) => {
	const params = httpParams.adminOrgParams(appVariables);
	const authSession = JSON.parse(localStorage.getItem("authenticateSession"));

	const [modalFormCollapse, setModalFormCollapse] = React.useState(null);
	const [modalFormSchema, setModalFormSchema] = React.useState(null);
	const [modalFormAdd, setModalFormAdd] = React.useState(false);
	const [isChildAdd, setIsChildAdd] = React.useState(false);
	const [fpError, setFPError] = React.useState(false);
	const [fpResponse, setFPResponse] = React.useState(null);
	const [fileManageModal, setFileManageModal] = React.useState(false);
	const [documentType, setDocumentType] = React.useState(null);
	const [multiFormIndex, setMultiFormIndex] = React.useState(null);

	const setOpenModalForm = (index) => {
		setModalFormAdd(true);
		setModalFormSchema(index);
	};
	const setCloseModalForm = () => {
		setModalFormAdd(false);
		setModalFormSchema(null);
	};

	const fingerCap = async (f, d) => {
		await captureFinger(f, d, setFPError, setFPResponse);
	};

	const generatePDF = async (pdfName) => {
		const pdf = new jsPDF();
		pdf.save(pdfName);
	};

	const noRowFormAccess = () => {
		let accessTo = true;
		modSchema.noRowFormAccessTo &&
			modSchema.noRowFormAccessTo.length > 0 &&
			modSchema.noRowFormAccessTo.map((e) => {
				if (authSession.role === e) {
					accessTo = false;
				} else {
					accessTo = true;
				}
			});
		return accessTo;
	};

	const noModalFormAccess = () => {
		let accessTo = true;
		modSchema.noModalFormAccessTo &&
			modSchema.noModalFormAccessTo.length > 0 &&
			modSchema.noModalFormAccessTo.map((e) => {
				if (authSession.role === e) {
					accessTo = false;
				} else {
					accessTo = true;
				}
			});
		return accessTo;
	};

	try {
		return (
			<div className="contentbar">
				{/* Start row */}
				<div className="row">
					{mainCrudAdd ? (
						<div className="col-md-12">
							<div className="card m-b-30">
								<div className="card-header">
									<div className="row align-items-center">
										<div className="col-6">Add App</div>
										<div className="col-6 text-right">
											<button
												className="btn btn-sm btn-primary"
												onClick={() => {
													closeAddMainCrud(setMainCrudAdd);
													setMainCrudAdd(false);
												}}
											>
												<i className="fa fa-times" />
											</button>
										</div>
									</div>
								</div>
								<div className="card-body">
									<Form
										key={`${modSchema.moduleName}${currentPage}add`}
										formSchema={modSchema.form.create}
										appVariables={appVariables}
										isAdd={true}
										setMainCrudAdd={setMainCrudAdd}
										closeAddMainCrud={closeAddMainCrud}
										primaryColor={primaryColor}
										secondaryColor={secondaryColor}
										favicon={favicon}
									/>
								</div>
							</div>
						</div>
					) : (
						<div className="col-md-12">
							<div className="card m-b-30">
								{(getData.data.formData.length > 1 || allData !== null) && (
									<div className="card-header">
										<div className="row align-items-center">
											<div className="col-12">
												<input
													type="search"
													placeholder={modSchema.searchTitle}
													className="form-control"
													onChange={(e) =>
														uiHelper.handleSearch(
															e,
															setAllData,
															modSchema,
															allData
														)
													}
												/>
											</div>
										</div>
									</div>
								)}
								<div className="card-body row">
									<div className="col-lg-12">
										<div className="card">
											<div className="card-header">
												<h5 className="card-title">
													{modSchema.form.update.formTitle}
												</h5>
											</div>
											<div className="card-body">
												{getData.data.formData.length > 0 ? (
													<Form
														key={`${modSchema.moduleName}${currentPage}${getData.data.formData}update`}
														defaultData={
															modSchema.filterBy
																? filteredData[currentPage - 1]
																: getData.data.formData[currentPage - 1]
														}
														formSchema={modSchema.form.update}
														modSchema={modSchema}
														appVariables={appVariables}
														crud={modSchema.form.crud}
														primaryColor={primaryColor}
														secondaryColor={secondaryColor}
														favicon={favicon}
													/>
												) : (
													<div>No Records Found!</div>
												)}
												{modSchema.isPageEditor && mainCrudAdd !== true && (
													<div style={{ textAlign: "right" }}>
														<a
															href={
																getData.data.formData[currentPage - 1]
																	.routePath + "?edit=true"
															}
															target="_blank"
															rel="noopener noreferrer"
															className="btn btn-info btn-sm mb-3"
														>
															Edit Page
														</a>
													</div>
												)}
												{modSchema.captureFingerPrint &&
													getData.data.formData[currentPage - 1] &&
													getData.data.formData[currentPage - 1]
														.applicationStatus === "APPROVED" &&
													mainCrudAdd !== true && (
														<div style={{ textAlign: "right" }}>
															{getData.data.formData[currentPage - 1][
																modSchema.fingerPrintField
															] ? (
																<>
																	{fpError && (
																		<div className="text-danger text-bold">
																			Something went wrong! please try again
																		</div>
																	)}
																	{!fpError && fpResponse === "success" && (
																		<div className="text-success text-bold">
																			Updated Fingerprint!
																		</div>
																	)}
																	<div className="text-success">
																		Fingerprint Captured
																	</div>
																	<button
																		className="btn btn-info btn-sm mb-3"
																		onClick={() => fingerCap(quality, timeout)}
																	>
																		Update Fingerprint
																	</button>
																</>
															) : (
																<>
																	{fpError && (
																		<div className="text-danger text-bold">
																			Something went wrong! please try again
																		</div>
																	)}
																	{!fpError && fpResponse === "success" && (
																		<div className="text-success text-bold">
																			Captured Fingerprint!
																		</div>
																	)}
																	<div className="text-danger">
																		No Fingerprint!
																	</div>
																	<button
																		className="btn btn-info btn-sm mb-3"
																		onClick={() => fingerCap(quality, timeout)}
																	>
																		Capture Fingerprint
																	</button>
																</>
															)}
														</div>
													)}

												{modSchema.filterBy
													? filteredData.length > 1 &&
													  mainCrudAdd !== true && (
															<div className="float-right mt-2">
																<Pagination
																	currentPage={currentPage}
																	totalCount={
																		modSchema.filterBy
																			? filteredData.length
																			: getData.data.formData.length
																	}
																	siblingCount={siblingCount}
																	pageSize={pageSize}
																	onPageChange={handlePage}
																	align="justify-content-end"
																	primaryColor={primaryColor}
																	secondaryColor={secondaryColor}
																	favicon={favicon}
																/>
															</div>
													  )
													: getData.data.formData.length > 1 &&
													  mainCrudAdd !== true && (
															<div className="float-right mt-2">
																<Pagination
																	currentPage={currentPage}
																	totalCount={
																		modSchema.filterBy
																			? filteredData.length
																			: getData.data.formData.length
																	}
																	siblingCount={siblingCount}
																	pageSize={pageSize}
																	onPageChange={handlePage}
																	align="justify-content-end"
																	primaryColor={primaryColor}
																	secondaryColor={secondaryColor}
																	favicon={favicon}
																/>
															</div>
													  )}
												<div className="mb-5">&nbsp;</div>
												<hr />
												{getData.data.formData.length > 0 &&
													modSchema.isMultiForm &&
													!mainCrudAdd && (
														<DocManagerForm
															appVariables={appVariables}
															key={`MF${currentPage - 1}`}
															schema={modSchema}
															multiFormIndex={multiFormIndex}
															setMultiFormIndex={setMultiFormIndex}
															generatePDF={generatePDF}
															getData={getData}
															activePage={currentPage}
															setFileManageModal={setFileManageModal}
															setDocumentType={setDocumentType}
															primaryColor={primaryColor}
															secondaryColor={secondaryColor}
															favicon={favicon}
														/>
													)}
												{getData.data.formData[currentPage - 1] &&
													modSchema.isModalRowForm &&
													mainCrudAdd !== true && (
														<div
															className="accordion accordion-outline mt-4"
															id="accordionoutline"
														>
															<div className="card">
																{modSchema.modalRowForm.map(
																	(modalForm, modalFormIndex) => (
																		<div
																			key={modalFormIndex}
																			style={{ marginBottom: "0.5rem" }}
																		>
																			<div className="card-header">
																				<h2 className="mb-0">
																					<button
																						className={`btn btn-link ${
																							modalFormIndex !==
																								modalFormCollapse && "collapsed"
																						}`}
																						type="button"
																						data-toggle="collapse"
																						data-target="#collapseOneoutline"
																						aria-expanded="false"
																						aria-controls="collapseOneoutline"
																						onClick={() =>
																							modalFormCollapse ===
																							modalFormIndex
																								? setModalFormCollapse(null)
																								: setModalFormCollapse(
																										modalFormIndex
																								  )
																						}
																					>
																						<i className="feather icon-list mr-2" />
																						{
																							modSchema.modalRowForm[
																								modalFormIndex
																							].formTitle
																						}
																					</button>
																				</h2>
																			</div>
																			<div
																				id="collapseOneoutline"
																				className={`collapse ${
																					modalFormIndex ===
																						modalFormCollapse && "show"
																				}`}
																				aria-labelledby="headingOneoutline"
																				data-parent="#accordionoutline"
																				style={{}}
																			>
																				<div className="card-body">
																					<button
																						className="btn btn-sm btn-primary"
																						onClick={() =>
																							setOpenModalForm(modalForm)
																						}
																					>
																						<i className="fa fa-plus"></i>
																					</button>
																					<hr />
																					{getData.data.formData[
																						currentPage - 1
																					] &&
																						getData.data.formData[
																							currentPage - 1
																						][
																							modSchema.modalRowForm[
																								modalFormIndex
																							].rowDataArray
																						].map((row, rowIndex) => (
																							<React.Fragment
																								key={`${modSchema.moduleName}${currentPage}${getData.data.formData}${rowIndex}modalForm`}
																							>
																								<Form
																									defaultData={row}
																									formSchema={
																										modSchema.modalRowForm[
																											modalFormIndex
																										].update
																									}
																									appVariables={appVariables}
																									mainData={
																										getData.data.formData[
																											currentPage - 1
																										]
																									}
																									crud={
																										modSchema.modalRowForm.crud
																									}
																									primaryColor={primaryColor}
																									secondaryColor={
																										secondaryColor
																									}
																									favicon={favicon}
																								/>
																								<hr />
																							</React.Fragment>
																						))}
																				</div>
																			</div>
																		</div>
																	)
																)}
															</div>
														</div>
													)}
												{noRowFormAccess() &&
													modSchema.isRowForm &&
													mainCrudAdd !== true && (
														<>
															<h6 className="card-title">
																{modSchema.rowForm.formTitle}
															</h6>
															<div>
																<button
																	type="button"
																	className="btn btn-sm btn-success-rgba"
																	onClick={() => setIsChildAdd(true)}
																>
																	<i className="feather icon-plus" />
																</button>
															</div>
															{isChildAdd && (
																<>
																	<Form
																		key={`${
																			modSchema.moduleName
																		}${currentPage}${
																			getData.data.formData
																		}${"rowIndex"}addrowForm`}
																		formSchema={modSchema.rowForm.create}
																		parentId={
																			modSchema.filterBy
																				? filteredData[currentPage - 1][
																						modSchema.rowForm.create.parentId
																				  ]
																				: getData.data.formData[
																						currentPage - 1
																				  ][modSchema.rowForm.create.parentId]
																		}
																		crud={modSchema.rowForm.crud}
																		appVariables={appVariables}
																		isRowForm={true}
																		isAdd={true}
																		isChildAdd={isChildAdd}
																		setIsChildAdd={setIsChildAdd}
																		primaryColor={primaryColor}
																		secondaryColor={secondaryColor}
																		favicon={favicon}
																	/>
																	<hr />
																</>
															)}
															{modSchema.filterBy
																? filteredData[currentPage - 1] &&
																  filteredData[currentPage - 1][
																		modSchema.rowForm.rowDataArray
																  ].map((row, rowIndex) => (
																		<Form
																			key={`${modSchema.moduleName}${currentPage}${getData.data.formData}${rowIndex}updatefilter`}
																			defaultData={row}
																			formSchema={modSchema.rowForm.update}
																			crud={modSchema.rowForm.crud}
																			appVariables={appVariables}
																			isRowForm={true}
																			primaryColor={primaryColor}
																			secondaryColor={secondaryColor}
																			favicon={favicon}
																		/>
																  ))
																: getData.data.formData[currentPage - 1] &&
																  getData.data.formData[currentPage - 1][
																		modSchema.rowForm.rowDataArray
																  ].map((row, rowIndex) => (
																		<Form
																			key={`${modSchema.moduleName}${currentPage}${getData.data.formData}${rowIndex}update`}
																			defaultData={row}
																			formSchema={modSchema.rowForm.update}
																			crud={modSchema.rowForm.crud}
																			appVariables={appVariables}
																			isRowForm={true}
																			primaryColor={primaryColor}
																			secondaryColor={secondaryColor}
																			favicon={favicon}
																		/>
																  ))}
														</>
													)}
												{fileManageModal && (
													<ModalFileManager
														appVariables={appVariables}
														schema={modSchema.fileManager}
														setFileManageModal={setFileManageModal}
														defaultData={getData.data.formData[currentPage - 1]}
														params={params}
														documentType={documentType}
														primaryColor={primaryColor}
														secondaryColor={secondaryColor}
														favicon={favicon}
													/>
												)}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					)}
					{/* End col */}
				</div>
				{noModalFormAccess() && modalFormAdd && (
					<div
						className={`modal fade bd-example-modal-lg ${
							modalFormAdd && "show"
						}`}
						tabIndex={-1}
						role="dialog"
						aria-modal="true"
						style={{ display: "block", backgroundColor: "rgba(55,55,55,0.3)" }}
					>
						<div className="modal-dialog modal-lg">
							<div className="modal-content">
								<div className="modal-body">
									<h3>{modalFormSchema.create.formTitle}</h3>
									<Form
										key={`${modSchema.moduleName}${currentPage}`}
										defaultData={getData.data.formData[currentPage - 1]}
										formSchema={modalFormSchema.create}
										appVariables={appVariables}
										isAdd={true}
										isModalForm={true}
										closeAddMainCrud={setCloseModalForm}
										parentId={
											modSchema.filterBy
												? filteredData[currentPage - 1][
														modalFormSchema.create.parentId
												  ]
												: getData.data.formData[currentPage - 1][
														modalFormSchema.create.parentId
												  ]
										}
										mainData={getData.data.formData[currentPage - 1]}
										primaryColor={primaryColor}
										secondaryColor={secondaryColor}
										favicon={favicon}
									/>
								</div>
							</div>
						</div>
					</div>
				)}
			</div>
		);
	} catch (err) {
		return (
			<E500
				primaryColor={primaryColor}
				secondaryColor={secondaryColor}
				favicon={favicon}
				error={err}
			/>
		);
	}
};
