import React from "react";
import { useForm } from "react-hook-form";
import * as Y from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { useCreateCall } from "../../lib/api/create";
import { E500 } from "../../components/e500";

export const ModalFileManager = (props) => {
	const createCallMutation = useCreateCall();
	const validation = Y.object().shape({
		document: Y.mixed().required("Choose a document to upload!"),
	});
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		mode: "onSubmit",
		resolver: yupResolver(validation),
	});

	const onFormSubmit = async (data, e) => {
		let formData = new FormData();
		formData.append("docType", props.documentType);
		formData.append("file", data.document[0]);
		//console.log(formData);
		await createCallMutation.mutateAsync({
			url: props.appVariables.REACT_APP_API_URL,
			apiEndpoint: "applDocument/" + props.defaultData.applicationId,
			parentAPI: "appl",
			data: formData,
			messageTitle: "Document",
			message: "Document uploaded successfully!",
			params: props.params,
		});
	};

	try {
		return (
			<div
				className={`modal fade bd-example-modal-lg show`}
				style={{ display: "block", backgroundColor: "rgba(55,55,55,0.3)" }}
			>
				<div className="modal-dialog modal-dialog-centered modal-md">
					<div className="modal-content">
						<div className="modal-body">
							<button
								type="button"
								className="close"
								data-dismiss="modal"
								aria-label="Close"
								onClick={() => props.setFileManageModal(false)}
							>
								<span aria-hidden="true">×</span>
							</button>
							<h4 className="mr-2 mb-5">Upload Document</h4>
							<form onSubmit={handleSubmit(onFormSubmit)} className="d-flex">
								<div className="form-group mb-3">
									<label className="custom-file d-block">
										<input
											type="file"
											{...register("document")}
											className="form-control"
											style={{
												borderTopRightRadius: "0px",
												borderBottomRightRadius: "0px",
											}}
										/>
										<span className="custom-file-control"></span>
									</label>
									{errors["document"] && (
										<div className="form-control-feedback">
											{errors["document"].message}
										</div>
									)}
								</div>
								<div>
									<button
										type="submit"
										className="btn btn-primary"
										style={{
											borderTopLeftRadius: "0px",
											borderBottomLeftRadius: "0px",
										}}
									>
										{createCallMutation.isLoading ? (
											<>
												<span
													className="spinner-border spinner-border-sm"
													role="status"
													aria-hidden="true"
												></span>{" "}
												Uploading...
											</>
										) : (
											<>Upload</>
										)}
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		);
	} catch (err) {
		return (
			<E500
				primaryColor={props.primaryColor}
				secondaryColor={props.secondaryColor}
				favicon={props.favicon}
				error={err}
			/>
		);
	}
};
