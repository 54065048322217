import React from "react";
import { E500 } from "../e500";

export const SelectBox = ({
	field,
	errors,
	register,
	isAdd,
	defaultData,
	mainData,
	primaryColor,
	secondaryColor,
	favicon,
}) => {
	try {
		return (
			<div
				className={`form-group col-md-${field.size}`}
				style={{ display: field.hidden ? "none" : "block" }}
			>
				<label className="form-control-label">{field.placeholder}</label>
				<select
					className={`form-control ${
						errors[field.name] && "form-control-danger"
					}`}
					style={{ color: "#000000" }}
					{...register(field.name)}
					defaultValue={defaultData ? defaultData[field.name] : null}
				>
					<option>Select {field.placeholder}...</option>
					{field.optionArrayDependency &&
						field.compOptionArrayDependency.map((option, optionIndex) => (
							<option key={optionIndex} value={option.id}>
								{option.name}
							</option>
						))}
					{field.valFromObj &&
						mainData[field.obj[0]] &&
						mainData[field.obj[0]].map((option, optionIndex) => (
							<option key={optionIndex} value={option[field.compId]}>
								{option[field.obj[1]]}
							</option>
						))}
					{field.optionAPIDependency &&
						JSON.parse(
							localStorage.getItem("authent")
						).state.dependeciesFormAPIS[
							field.compOptionAPIDependency
						]?.formData.map((option, optionIndex) => (
							<option key={optionIndex} value={option[field.compId]}>
								{option[field.compName]}
							</option>
						))}
				</select>
				{errors[field.name] && (
					<div className="form-control-feedback">
						{errors[field.name].message}
					</div>
				)}
			</div>
		);
	} catch (err) {
		return (
			<E500
				primaryColor={primaryColor}
				secondaryColor={secondaryColor}
				favicon={favicon}
				error={err}
			/>
		);
	}
};
