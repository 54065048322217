import React from "react";
import "../../assets/themes/t1.css";

export const T1Content1 = ({ pageData }) => {
	return (
		<section class="t1-Feautes t1-section mt-5">
			<div class="container">
				<div class="row">
					<div class="col-lg-12">
						<div class="t1-section-title" style={{ padding: "0" }}>
							<h2 className="t1-why-choose t1-choose-left">
								{pageData.content.content.header}
							</h2>
							<hr />
							<p>{pageData.content.content.text}</p>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};
