import React from "react";
import * as WebDataRocksReact from "react-webdatarocks";
import { Charts } from "./reporting/charts";

import "webdatarocks/webdatarocks.css";

function customizeToolbar(toolbar) {
	var tabs = toolbar.getTabs(); // get all tabs from the toolbar
	toolbar.getTabs = function () {
		delete tabs[0];
		delete tabs[1];
		delete tabs[2];
		return tabs;
	};
}

const reports = [
	{
		name: "Area Chart",
		chartType: "AreaChart",
		colSize: "col-md-4",
		reportData: {
			data: [
				["Year", "Sales", "Expenses"],
				["2013", 1000, 400],
				["2014", 1170, 460],
				["2015", 660, 1120],
				["2016", 1030, 540],
			],
			options: {
				title: "Company Performance",
				hAxis: { title: "Year", titleTextStyle: { color: "#333" } },
				vAxis: { minValue: 0 },
				chartArea: { width: "50%", height: "70%" },
			},
		},
	},
	{
		name: "Bar",
		chartType: "Bar",
		colSize: "col-md-4",
		reportData: {
			data: [
				["Year", "Sales", "Expenses", "Profit"],
				["2014", 1000, 400, 200],
				["2015", 1170, 460, 250],
				["2016", 660, 1120, 300],
				["2017", 1030, 540, 350],
			],
			options: {
				chart: {
					title: "Company Performance",
					subtitle: "Sales, Expenses, and Profit: 2014-2017",
				},
			},
		},
	},
	{
		name: "Bar Chart",
		chartType: "BarChart",
		colSize: "col-md-4",
		reportData: {
			data: [
				["City", "2010 Population", "2000 Population"],
				["New York City, NY", 8175000, 8008000],
				["Los Angeles, CA", 3792000, 3694000],
				["Chicago, IL", 2695000, 2896000],
				["Houston, TX", 2099000, 1953000],
				["Philadelphia, PA", 1526000, 1517000],
			],
			options: {
				title: "Population of Largest U.S. Cities",
				chartArea: { width: "50%" },
				hAxis: {
					title: "Total Population",
					minValue: 0,
				},
				vAxis: {
					title: "City",
				},
			},
		},
	},
	{
		name: "Bubble Chart",
		chartType: "BubbleChart",
		colSize: "col-md-4",
		reportData: {
			data: [
				["ID", "Life Expectancy", "Fertility Rate", "Region", "Population"],
				["CAN", 80.66, 1.67, "North America", 33739900],
				["DEU", 79.84, 1.36, "Europe", 81902307],
				["DNK", 78.6, 1.84, "Europe", 5523095],
				["EGY", 72.73, 2.78, "Middle East", 79716203],
				["GBR", 80.05, 2, "Europe", 61801570],
				["IRN", 72.49, 1.7, "Middle East", 73137148],
				["IRQ", 68.09, 4.77, "Middle East", 31090763],
				["ISR", 81.55, 2.96, "Middle East", 7485600],
				["RUS", 68.6, 1.54, "Europe", 141850000],
				["USA", 78.09, 2.05, "North America", 307007000],
			],
			options: {
				title:
					"Correlation between life expectancy, fertility rate " +
					"and population of some world countries (2010)",
				hAxis: { title: "Life Expectancy" },
				vAxis: { title: "Fertility Rate" },
				bubble: { textStyle: { fontSize: 11 } },
			},
		},
	},
	{
		name: "Column Chart",
		chartType: "ColumnChart",
		colSize: "col-md-4",
		reportData: {
			data: [
				["Element", "Density", { role: "style" }],
				["Copper", 8.94, "#b87333"], // RGB value
				["Silver", 10.49, "silver"], // English color name
				["Gold", 19.3, "gold"],
				["Platinum", 21.45, "color: #e5e4e2"], // CSS-style declaration
			],
			options: {
				title:
					"Correlation between life expectancy, fertility rate " +
					"and population of some world countries (2010)",
				hAxis: { title: "Life Expectancy" },
				vAxis: { title: "Fertility Rate" },
				bubble: { textStyle: { fontSize: 11 } },
			},
		},
	},
	{
		name: "Combo Chart",
		chartType: "ComboChart",
		colSize: "col-md-4",
		reportData: {
			data: [
				[
					"Month",
					"Bolivia",
					"Ecuador",
					"Madagascar",
					"Papua New Guinea",
					"Rwanda",
					"Average",
				],
				["2004/05", 165, 938, 522, 998, 450, 614.6],
				["2005/06", 135, 1120, 599, 1268, 288, 682],
				["2006/07", 157, 1167, 587, 807, 397, 623],
				["2007/08", 139, 1110, 615, 968, 215, 609.4],
				["2008/09", 136, 691, 629, 1026, 366, 569.6],
			],
			options: {
				title: "Monthly Coffee Production by Country",
				vAxis: { title: "Cups" },
				hAxis: { title: "Month" },
				seriesType: "bars",
				series: { 5: { type: "line" } },
			},
		},
	},
	{
		name: "Line",
		chartType: "Line",
		colSize: "col-md-4",
		reportData: {
			data: [
				[
					"Day",
					"Guardians of the Galaxy",
					"The Avengers",
					"Transformers: Age of Extinction",
				],
				[1, 37.8, 80.8, 41.8],
				[2, 30.9, 69.5, 32.4],
				[3, 25.4, 57, 25.7],
				[4, 11.7, 18.8, 10.5],
				[5, 11.9, 17.6, 10.4],
				[6, 8.8, 13.6, 7.7],
				[7, 7.6, 12.3, 9.6],
				[8, 12.3, 29.2, 10.6],
				[9, 16.9, 42.9, 14.8],
				[10, 12.8, 30.9, 11.6],
				[11, 5.3, 7.9, 4.7],
				[12, 6.6, 8.4, 5.2],
				[13, 4.8, 6.3, 3.6],
				[14, 4.2, 6.2, 3.4],
			],
			options: {
				chart: {
					title: "Box Office Earnings in First Two Weeks of Opening",
					subtitle: "in millions of dollars (USD)",
				},
			},
		},
	},
	{
		name: "Line Chart",
		chartType: "LineChart",
		colSize: "col-md-4",
		reportData: {
			data: [
				["Year", "Sales", "Expenses"],
				["2004", 1000, 400],
				["2005", 1170, 460],
				["2006", 660, 1120],
				["2007", 1030, 540],
			],
			options: {
				title: "Company Performance",
				curveType: "function",
				legend: { position: "bottom" },
			},
		},
	},
	{
		name: "Pie Chart",
		chartType: "PieChart",
		colSize: "col-md-4",
		reportData: {
			data: [
				["Task", "Hours per Day"],
				["Work", 11],
				["Eat", 2],
				["Commute", 2],
				["Watch TV", 2],
				["Sleep", 7],
			],
			options: {
				title: "My Daily Activities",
			},
		},
	},
	{
		name: "Tree Map",
		chartType: "TreeMap",
		colSize: "col-md-4",
		reportData: {
			data: [
				[
					"Location",
					"Parent",
					"Market trade volume (size)",
					"Market increase/decrease (color)",
				],
				["Global", null, 0, 0],
				["America", "Global", 0, 0],
				["Europe", "Global", 0, 0],
				["Asia", "Global", 0, 0],
				["Australia", "Global", 0, 0],
				["Africa", "Global", 0, 0],
				["Brazil", "America", 11, 10],
				["USA", "America", 52, 31],
				["Mexico", "America", 24, 12],
				["Canada", "America", 16, -23],
				["France", "Europe", 42, -11],
				["Germany", "Europe", 31, -2],
				["Sweden", "Europe", 22, -13],
				["Italy", "Europe", 17, 4],
				["UK", "Europe", 21, -5],
				["China", "Asia", 36, 4],
				["Japan", "Asia", 20, -12],
				["India", "Asia", 40, 63],
				["Laos", "Asia", 4, 34],
				["Mongolia", "Asia", 1, -5],
				["Israel", "Asia", 12, 24],
				["Iran", "Asia", 18, 13],
				["Pakistan", "Asia", 11, -52],
				["Egypt", "Africa", 21, 0],
				["S. Africa", "Africa", 30, 43],
				["Sudan", "Africa", 12, 2],
				["Congo", "Africa", 10, 12],
				["Zaire", "Africa", 8, 10],
			],
			options: {
				minColor: "#f00",
				midColor: "#ddd",
				maxColor: "#0d0",
				headerHeight: 15,
				fontColor: "black",
				showScale: true,
			},
		},
	},
];

export const Reports = ({ formData }) => {
	return (
		<>
			<div>
				Pivot
				<WebDataRocksReact.Pivot
					width="100%"
					beforetoolbarcreated={customizeToolbar}
					report={{ dataSource: { data: formData } }}
					toolbar={true}
				/>
			</div>
			<div className="row">
				{reports.length > 0 &&
					reports.map((report, reportIndex) => (
						<React.Fragment key={reportIndex}>
							<Charts reportData={report} />
						</React.Fragment>
					))}
			</div>
		</>
	);
};
