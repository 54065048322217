import React from "react";
import { E500 } from "../e500";

export const SwitchBox = ({
	field,
	errors,
	register,
	isAdd,
	defaultData,
	primaryColor,
	secondaryColor,
	favicon,
}) => {
	const [switchButton, setSwitchButton] = React.useState(
		isAdd ? field.defaultValue : defaultData[field.name]
	);

	const Checkbox = ({
		fnClick,
		fnChange,
		title = "",
		checked = false,
		register,
		field,
	}) => (
		<>
			<div
				className={`form-group col-md-${field.size}`}
				style={{ display: field.hidden ? "none" : "block" }}
			>
				{field.placeholder}
				<br />
				<label className="mt-3">
					<input
						onClick={(e) => {
							if (fnClick !== undefined) fnClick(e.target.checked);
						}}
						onChange={(e) => {
							if (fnChange !== undefined) fnChange(e.target.checked);
						}}
						type="checkbox"
						style={{ width: "1.5rem", height: "1.5rem" }}
						checked={checked}
						{...register(field.name)}
					/>
				</label>
			</div>
		</>
	);
	try {
		return (
			<Checkbox
				title="Click"
				fnClick={(v) => setSwitchButton(!switchButton)}
				checked={switchButton}
				register={register}
				field={field}
			/>
		);
	} catch (err) {
		return (
			<E500
				primaryColor={primaryColor}
				secondaryColor={secondaryColor}
				favicon={favicon}
				error={err}
			/>
		);
	}
};
