import React from "react";
import { E500 } from "../../components/e500";

export const MessageModal = (props) => {
	try {
		return (
			<div
				className={`modal fade bd-example-modal-lg show`}
				style={{ display: "block", backgroundColor: "rgba(55,55,55,0.3)" }}
			>
				<div className="modal-dialog modal-dialog-centered modal-md">
					<div className="modal-content">
						<div className="modal-body  pt-2">
							<button
								type="button"
								className="close"
								data-dismiss="modal"
								aria-label="Close"
								onClick={() => props.setIsAdd(false)}
							>
								<span aria-hidden="true">×</span>
							</button>
							<div className="row">
								<div className="col-12">
									<div className="modal-bg">
										<h2 className="font-light m-b-0 mb-4">New Message</h2>

										<form
											className="row"
											onSubmit={props.handleSubmit(props.sendMessage)}
										>
											<div className="col-12 input-group mb-3">
												<input
													type="text"
													className="form-control "
													placeholder="Subject"
													aria-describedby="basic-addon1"
													{...props.register("subject")}
												/>
											</div>
											<div className="col-12 input-group mb-3">
												<textarea
													className="form-control"
													placeholder="Your Message Here...."
													{...props.register("message")}
												></textarea>
											</div>
											<div className="col-12 input-group mb-3 text-right">
												<button
													type="submit"
													className="btn btn-primary"
													style={{
														borderTopLeftRadius: "0px",
														borderBottomLeftRadius: "0px",
													}}
												>
													{props.createCallMutation.isLoading ? (
														<>
															<span
																className="spinner-border spinner-border-sm"
																role="status"
																aria-hidden="true"
															></span>{" "}
															Sending...
														</>
													) : (
														<>Send</>
													)}
												</button>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
					{/* /.modal-content */}
				</div>
				{/* /.modal-dialog */}
			</div>
		);
	} catch (err) {
		return (
			<E500
				primaryColor={props.primaryColor}
				secondaryColor={props.secondaryColor}
				favicon={props.favicon}
				error={err}
			/>
		);
	}
};
