import React from "react";
import { E500 } from "../e500";

export const FileChooser = ({
	field,
	errors,
	register,
	isAdd,
	defaultData,
	setImageFile,
	primaryColor,
	secondaryColor,
	favicon,
}) => {
	try {
		return (
			<div
				className={`form-group col-md-${field.size}`}
				style={{ display: field.hidden ? "none" : "block" }}
			>
				<label htmlFor="inputEmail4">{field.placeholder}</label>
				<input
					type="file"
					className={`form-control ${errors[field.name] && "is-invalid"}`}
					placeholder={field.placeholder}
					{...register(field.name)}
				/>
				{errors[field.name] && (
					<div className="invalid-feedback">{errors[field.name].message}</div>
				)}
			</div>
		);
	} catch (err) {
		return (
			<E500
				primaryColor={primaryColor}
				secondaryColor={secondaryColor}
				favicon={favicon}
				error={err}
			/>
		);
	}
};
