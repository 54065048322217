import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { appDataProcessor } from "./lib/uihelper";
import axios from "axios";

import httpParams from "./lib/httpParams";
import { useGetCall } from "./lib/api/get";

import { PrivateRoute } from "./routes/privateRoute";
import { ModuleLayout } from "./modules";
import favicon from "./assets/images/favicon.png";
import { Landing } from "./components/landing";
import { E404 } from "./components/e404";
import { Website } from "./website";
import { useAuthStore } from "./store/auth";
import { Notifications } from "./components/notifications/notifications";
import { E500 } from "./components/e500";

import "./App.css";

function App({ appVariables }) {
	const [isOnline, setIsOnline] = React.useState(true);
	const [isFetching, setIsFetching] = React.useState(false);
	const params = httpParams.uiParams(appVariables);
	const primaryColor = appVariables.REACT_APP_PRIMARY_COLOR;
	const secondaryColor = appVariables.REACT_APP_SECONDARY_COLOR;

	const getAppData = useGetCall(
		appVariables.REACT_APP_API_URL_WEB,
		"appData",
		params
	);

	const authDetails = useAuthStore((state) => state);
	const { setAuthentication, unsetAuthentication, setRoleAccess, unsetRole } =
		useAuthStore();

	const userLogout = () => {
		const isLocalEnvironment =
			process.env.REACT_APP_USE_API_URL_LOCAL === "1"
				? ""
				: process.env.REACT_APP_API_URL_LOCAL;
		const redirectURL = window.location.origin;
		localStorage.removeItem("authenticateSession");
		localStorage.removeItem("authent");
		localStorage.removeItem("loggedIn");

		unsetAuthentication();
		unsetRole();
		window.open(
			isLocalEnvironment + "/auth/logout?url=" + redirectURL,
			"_self"
		);
	};

	React.useEffect(() => {
		if (
			appVariables.REACT_APP_APP_ISONLINE &&
			appVariables.REACT_APP_APP_ISONLINE === "1"
		) {
			window.addEventListener("online", () => setIsOnline(navigator.onLine));
			window.addEventListener("offline", () => setIsOnline(navigator.onLine));
			return () => {
				window.removeEventListener("online", () =>
					setIsOnline(navigator.onLine)
				);
				window.addEventListener("offline", () => setIsOnline(navigator.onLine));
			};
		} else {
			return null;
		}
	});

	React.useEffect(() => {
		const isLocalEnvironment =
			process.env.REACT_APP_USE_API_URL_LOCAL === "1"
				? ""
				: process.env.REACT_APP_API_URL_LOCAL;
		const getUser = async () => {
			await fetch(isLocalEnvironment + "/auth/login/success", {
				method: "GET",
				credentials: "include",
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json",
					"Access-Control-Allow-Credentials": true,
				},
			})
				.then((response) => {
					if (response.status === 200) return response.json();
					throw new Error("authentication has been failed!");
				})
				.then(async (resObject) => {
					localStorage.setItem("loggedIn", true);
					if (!localStorage.getItem("authenticateSession")) {
						getUserSession();
					}

					await setAuthentication(
						{
							name: resObject.user.profile.displayName,
							email: resObject.user.profile.emails[0].value,
							profileImg: resObject.user.profile.photos[0].value,
							tokenObject: resObject.tokenObject,
							profile: {},
						},
						"passport",
						resObject,
						"",
						""
					);
				})
				.catch((err) => {
					//console.log(err);
				});
		};
		const getUserSession = async () => {
			setIsFetching(true);
			const isLocalEnvironment =
				process.env.REACT_APP_USE_API_URL_LOCAL === "1"
					? ""
					: process.env.REACT_APP_API_URL_LOCAL;
			await axios
				.get(isLocalEnvironment + "/authenticateSession", {
					method: "GET",
					withCredentials: true,
					headers: {
						Accept: "application/json",
						"Content-Type": "application/json",
						"Access-Control-Allow-Credentials": true,
					},
				})
				.then(async (tres) => {
					const authSession = {};

					authSession["session"] = tres.data.formData;
					const defaultRole = tres.data.formData.user.userRoles.filter(
						(f) => f.roleName === "AppUser"
					);
					authDetails["role"] = defaultRole[0].roleName;
					authDetails["roleId"] = defaultRole[0].roleId;
					authSession["role"] = defaultRole[0].roleName;
					authSession["roleId"] = defaultRole[0].roleId;

					localStorage.setItem(
						"authenticateSession",
						JSON.stringify(authSession)
					);
				})
				.catch((err) => {
					//console.log()
				});
			setIsFetching(false);
		};

		getUser();

		if (
			!localStorage.getItem("authenticateSession") &&
			localStorage.getItem("loggedIn")
		) {
			getUserSession();
		}
	}, []);

	if (getAppData.isLoading) {
		return (
			<>
				<div
					className="spinner-border"
					role="status"
					style={{
						position: "fixed",
						top: "50%",
						left: "50%",
						color: primaryColor,
					}}
				>
					<span className="sr-only">Loading...</span>
				</div>
				<span className="sr-only">Loading...</span>
			</>
		);
	}

	if (isFetching) {
		return (
			<>
				<div
					className="spinner-border"
					role="status"
					style={{
						position: "fixed",
						top: "50%",
						left: "50%",
						color: primaryColor,
					}}
				>
					<span className="sr-only">Loading...</span>
				</div>
				<span className="sr-only">Loading...</span>
			</>
		);
	}

	const appDataParsed = appDataProcessor(getAppData);

	const applicationSchema = getAppData.data?.formData[0]
		? JSON.parse(getAppData.data?.formData[0].appElementsData)
		: {};

	const appSettings =
		applicationSchema.length > 0 &&
		applicationSchema.filter((f) => "appSettings" in f)[0].appSettings;
	const webSettings =
		applicationSchema.length > 0 &&
		applicationSchema.filter((f) => "webSettings" in f)[0].webSettings;
	const seo =
		applicationSchema.length > 0 &&
		applicationSchema.filter((f) => "seo" in f)[0].seo;
	try {
		return (
			<Router>
				<Notifications />
				<Routes key={authDetails}>
					{appDataParsed.routesData.length > 0 ? (
						appDataParsed.routesData.map((route, routeIndex) => (
							<Route
								path={route.path}
								key={`${routeIndex}${route.name}`}
								exact
								element={
									<Website
										key={route.path}
										authData={authDetails}
										userLogout={userLogout}
										primaryColor={primaryColor}
										secondaryColor={secondaryColor}
										favicon={appVariables.REACT_APP_THEME_FAVICON}
										appVariables={appVariables}
										routeData={route}
										appSettings={appSettings}
										webSettings={webSettings}
										seo={seo}
									/>
								}
							/>
						))
					) : (
						<>
							<Route
								path="/"
								exact
								element={
									<Landing
										primaryColor={primaryColor}
										secondaryColor={secondaryColor}
										favicon={appVariables.REACT_APP_THEME_FAVICON}
										authData={authDetails}
										userLogout={userLogout}
									/>
								}
							/>
						</>
					)}
					{authDetails.isLoggedIn &&
						appDataParsed.routesData.length &&
						appDataParsed.routesData.length > 0 &&
						appDataParsed.routesData.map(
							(mod, modInd) =>
								mod.isPrivate &&
								webSettings["webSettings-contactForm"].enabledModules[
									mod.name
								] && (
									<Route
										key={`${modInd}${mod.component}${mod.name}`}
										path={`console/${mod.path}`}
										element={
											<PrivateRoute
												key={`${modInd}${mod.component}${mod.name}`}
												appVariables={appVariables}
												sessionIsActive={true}
												authData={authDetails}
												authsSession={
													JSON.parse(
														localStorage.getItem("authenticateSession")
													) || null
												}
												setRoleAccess={setRoleAccess}
												isAuthenticated={authDetails.isLoggedIn}
												roleActive={authDetails.role}
												module={mod.name}
												navigateRoute="/console/my-profile"
												appSettings={appDataParsed.appSettings}
												webSettings={appDataParsed.webSettings}
												modSchema={mod.apiEndPointSchema}
												routeDetails={mod}
												routesData={appDataParsed.routesData}
												primaryColor={primaryColor}
												secondaryColor={secondaryColor}
												favicon={appVariables.REACT_APP_THEME_FAVICON}
												userLogout={userLogout}
												isOnline={isOnline}
											>
												<ModuleLayout
													appVariables={appVariables}
													moduleSchema={mod.apiEndPointSchema}
													roleActive={authDetails.role}
													module={mod.name}
													schema={mod.apiEndPointSchema}
													authData={authDetails}
													authsSession={
														JSON.parse(
															localStorage.getItem("authenticateSession")
														) || null
													}
													session={authDetails.session}
													appSettings={appDataParsed.appSettings}
													webSettings={appDataParsed.webSettings}
													routesData={appDataParsed.routesData}
													primaryColor={primaryColor}
													secondaryColor={secondaryColor}
													favicon={appVariables.REACT_APP_THEME_FAVICON}
													userLogout={userLogout}
													routeDetails={mod}
													isOnline={isOnline}
												/>
											</PrivateRoute>
										}
									/>
								)
						)}
					<Route
						path="*"
						element={
							<E404
								primaryColor={primaryColor}
								secondaryColor={secondaryColor}
								favicon={appVariables.REACT_APP_THEME_FAVICON}
								appVariables={appVariables}
							/>
						}
					/>
				</Routes>
			</Router>
		);
	} catch (err) {
		return (
			<E500
				primaryColor={primaryColor}
				secondaryColor={secondaryColor}
				favicon={appVariables.REACT_APP_THEME_FAVICON}
				error={err}
				appVariables={appVariables}
			/>
		);
	}
}

export default App;
