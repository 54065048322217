import React from "react";
import { useOnClickOutside } from "./lib/OnClickOutside";
import { E500 } from "../components/e500";

export const ModalLogin = ({
	closeModal,
	setLoginModal,
	primaryColor,
	secondaryColor,
	favicon,
	appVariables,
}) => {
	const modalClose = React.useRef();
	useOnClickOutside(modalClose, () => closeModal(setLoginModal));

	const redirectURL = window.location.origin;
	const isLocalEnvironment =
		process.env.REACT_APP_USE_API_URL_LOCAL === "1"
			? ""
			: process.env.REACT_APP_API_URL_LOCAL;
	const emailLogin = () => {
		window.open(isLocalEnvironment + "/auth/login", "_self");
	};
	const googleLogin = () => {
		window.open(
			isLocalEnvironment + "/auth/google?url=" + redirectURL,
			"_self"
		);
	};
	try {
		return (
			<div
				className="modal fade bd-example-modal-lg show"
				tabIndex={-1}
				role="dialog"
				aria-modal="true"
				style={{ display: "block", backgroundColor: "rgba(55,55,55,0.3)" }}
			>
				<div className="modal-dialog modal-md" ref={modalClose}>
					<div className="modal-content">
						<div className="modal-body">
							<button
								type="button"
								className="close"
								data-dismiss="modal"
								aria-label="Close"
								onClick={() => closeModal(setLoginModal)}
							>
								<span aria-hidden="true">×</span>
							</button>
							<div id="containerbar" className="containerbar authenticate-bg">
								<div className="container">
									<div className="auth-box login-box">
										<div className="row no-gutters align-items-center justify-content-center">
											<div className="col-md-12">
												<div className="auth-box-right">
													<div className="card">
														<div className="card-body">
															<form action="#">
																<h4 className="text-primary mb-4">Log in !</h4>
																{appVariables.REACT_APP_LOCAL_LOGIN &&
																	appVariables.REACT_APP_LOCAL_LOGIN ===
																		"1" && (
																		<button
																			type="button"
																			className="btn btn-success btn-lg btn-block font-18"
																			onClick={emailLogin}
																		>
																			Log in with email
																		</button>
																	)}
															</form>
															<div className="login-or">
																<h6 className="text-muted">OR</h6>
															</div>
															<div className="social-login text-center">
																{appVariables.REACT_APP_GOOGLE_LOGIN &&
																	appVariables.REACT_APP_GOOGLE_LOGIN ===
																		"1" && (
																		<button
																			type="button"
																			className="btn btn-danger-rgba btn-lg btn-block font-18"
																			onClick={googleLogin}
																		>
																			<i className="fa fa-google mr-2" />
																			Log in with Google
																		</button>
																	)}
																{appVariables.REACT_APP_FACEBOOK_LOGIN &&
																	appVariables.REACT_APP_FACEBOOK_LOGIN ===
																		"1" && (
																		<button
																			type="submit"
																			className="btn btn-primary-rgba btn-lg btn-block font-18"
																		>
																			<i className="fa fa-facebook-f mr-2" />
																			Log in with Facebook
																		</button>
																	)}
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	} catch (err) {
		return (
			<E500
				primaryColor={primaryColor}
				secondaryColor={secondaryColor}
				favicon={favicon}
				error={err}
			/>
		);
	}
};
