import { Navigate } from "react-router-dom";
import React from "react";
import { ModuleLayout } from "../modules";
import { E500 } from "../components/e500";

export const PrivateRoute = (props) => {
	try {
		if (props.isAuthenticated) {
			const authsSession = localStorage.getItem("authenticateSession")
				? JSON.parse(localStorage.getItem("authenticateSession"))
				: null;

			const modRole = authsSession?.session?.pages.filter(
				(f) => f.pageName === props.module
			);

			const modAccess =
				modRole &&
				modRole[0]?.roles?.filter((f) => f.roleName === authsSession.role);
			const siteTitle = document.getElementById("siteTitle");
			siteTitle.innerHTML =
				props.routeDetails.pageTitle +
				" :: " +
				props.appVariables.REACT_APP_PAGE_TITLE;

			if (
				(modAccess &&
					modAccess.length > 0 &&
					modAccess[0].PageRole.accessLevel > 1) ||
				props.routeDetails.apiEndPointSchema.accessByAll
			) {
				return (
					<>
						<ModuleLayout
							appVariables={props.appVariables}
							key={props.routeDetails}
							auth={props.auth}
							sessionIsActive={props.sessionIsActive}
							authData={props.authData}
							authsSession={props.authsSession}
							setRoleAccess={props.setRoleAccess}
							setRoleModal={props.setRoleModal}
							setOpenMenu={props.setOpenMenu}
							openMenu={props.openMenu}
							moduleSchema={props.modSchema}
							routesData={props.routesData}
							primaryColor={props.primaryColor}
							secondaryColor={props.secondaryColor}
							favicon={props.favicon}
							userLogout={props.userLogout}
							modSchema={props.modSchema}
							routeDetails={props.routeDetails}
							isOnline={props.isOnline}
							webSettings={props.webSettings}
						/>
					</>
				);
			}
		}
		return <Navigate to={props.navigateRoute} />;
	} catch (err) {
		return (
			<E500
				primaryColor={props.primaryColor}
				secondaryColor={props.secondaryColor}
				favicon={props.favicon}
				error={err}
			/>
		);
	}
};
