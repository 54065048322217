import { create } from "zustand";
import { persist } from "zustand/middleware";
import shallow from "zustand/shallow";

export const useDependencies = create(
	persist(
		(set, get) => ({
			setDependency: (dependencies, role, roleId) => {
				set((state) => ({
					...dependencies,
					role: role,
					roleId: roleId,
				}));
			},
			unsetDependency: (dependecies, role, roleId) => {
				const depends = dependecies;
				depends["dependeciesFormAPIS"] = {};
				depends["role"] = role;
				depends["roleId"] = roleId;
				set(
					(state) => ({
						...depends,
					}),
					shallow
				);
			},
		}),
		{
			name: "authent",
		}
	)
);

export const useUnsetDependencies = create(
	persist(
		(set, get) => ({
			unsetDependency: (role, roleId) => {
				console.log("unsetting");
				set((state) => ({
					role: role,
					roleId: roleId,
					dependeciesFormAPIS: {},
				}));
			},
		}),
		{
			name: "authent",
		}
	)
);
