import React from "react";

import {
	setSideMenu,
	setTopBarMenu,
	setSideMenuMobile,
	mainUILoad,
	openModal,
	closeModal,
	addMainCrud,
	closeAddMainCrud,
} from "../lib/uihelper";
import logo from "../assets/images/logo.png";
import small_logo from "../assets/images/small_logo.png";
import "../assets/css/bootstrap.min.css";
import "../assets/css/icons.css";
import "../assets/css/flag-icon.min.css";
import "../assets/css/style.css";
import "../assets/css/custom.css";
import { ModalLogin } from "./modal";
import { Roles } from "../modules/roles";
import { E500 } from "./e500";
import { Link } from "react-router-dom";
export const Landing = ({
	authData,
	userLogout,
	primaryColor,
	secondaryColor,
	favicon,
	appVariables,
}) => {
	mainUILoad(primaryColor, secondaryColor, favicon);

	const [userRoles, setUserRoles] = React.useState(false);
	const [loginModal, setLoginModal] = React.useState(false);
	const authSession = localStorage.getItem("authenticateSession") || null;
	try {
		return (
			<div>
				<div id="containerbar" className="containerbar authenticate-bg">
					<div className="container">
						<div className="auth-box error-box">
							<div className="row no-gutters align-items-center justify-content-center">
								<div className="col-md-8 col-lg-6">
									<div className="text-center">
										<img
											src={appVariables.REACT_APP_THEME_LOGO}
											className="img-fluid error-logo"
											alt="logo"
										/>
										<img
											src={
												require("../assets/images/error/maintenance.svg")
													.default
											}
											className="img-fluid error-image"
											alt="maintenance"
											style={{ margin: "1rem" }}
										/>
										<h4 className="error-subtitle mb-4">
											System is under maintenance
										</h4>
										<p className="mb-4">
											We sincerely apologize for the inconvenience. Please, come
											back later.
										</p>
										{authData.isLoggedIn ? (
											<>
												<button
													onClick={() => userLogout()}
													className="btn btn-primary font-16"
												>
													<i className="feather icon-user mr-2" /> Logout
												</button>
												<Link
													to="console/my-profile"
													className="btn btn-primary font-16 ml-2"
												>
													<i className="feather icon-user mr-2" /> My Profile
												</Link>
											</>
										) : (
											<button
												onClick={() => openModal(setLoginModal)}
												className="btn btn-primary font-16"
											>
												<i className="feather icon-user mr-2" /> Login
											</button>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				{loginModal && (
					<ModalLogin
						closeModal={closeModal}
						setLoginModal={setLoginModal}
						primaryColor={primaryColor}
						secondaryColor={secondaryColor}
						favicon={favicon}
						appVariables={appVariables}
					/>
				)}
			</div>
		);
	} catch (err) {
		return (
			<E500
				primaryColor={primaryColor}
				secondaryColor={secondaryColor}
				favicon={favicon}
				error={err}
			/>
		);
	}
};
