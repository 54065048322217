import React from "react";
import { default as dayjs } from "dayjs";
import "./receiptPrint.css";
import { E500 } from "../../components/e500";

export const ReceiptView = ({
	appVariables,
	orgDetails,
	selectedAppl,
	primaryColor,
	secondaryColor,
	favicon,
}) => {
	try {
		return (
			<div id="section-to-print" className="row justify-content-center">
				<div className="col-md-12 col-lg-10 col-xl-10">
					<div className="card m-b-30">
						<div className="card-body">
							<div className="invoice">
								<div className="invoice-head">
									<div className="row">
										<div className="col-12 col-md-7 col-lg-7">
											<div className="invoice-logo">
												<img
													src={appVariables.REACT_APP_THEME_LOGO}
													className="img-fluid"
													alt="invoice-logo"
												/>
											</div>
											<h4>{orgDetails !== null && orgDetails.orgName}</h4>
											<p>{orgDetails !== null && orgDetails.orgHeader}</p>
											<p className="mb-0">
												{orgDetails !== null && orgDetails.orgStreetAddress}
											</p>
										</div>
										<div className="col-12 col-md-5 col-lg-5">
											<div className="invoice-name">
												<h5 className="text-uppercase mb-3">Fee Receipt</h5>
												<p className="mb-1">
													No : {selectedAppl.feeReceiptNumber}
												</p>
												<p className="mb-0">
													{dayjs(selectedAppl.feeReceivedDate).format(
														"MMM D, YYYY"
													)}
												</p>
												<h4 className="text-success mb-0 mt-3">{`${selectedAppl.feeCurrency} ${selectedAppl.feeAmount}`}</h4>
											</div>
										</div>
									</div>
								</div>
								<div className="invoice-summary-total">
									<div className="row">
										<div className="col-md-12 order-2 order-lg-1 col-lg-5 col-xl-6">
											<div className="order-note">
												<p className="mb-3">
													<span className="badge badge-info-inverse font-14">
														{selectedAppl.feeType}
													</span>
												</p>
												<h6>{selectedAppl.feeAmountInWords}</h6>
												<p>{selectedAppl.feeReceiptNotes}</p>
											</div>
										</div>
									</div>
								</div>
								<div className="invoice-footer">
									<div className="row align-items-center">
										<div className="col-md-6">
											<p className="mb-0">
												Thank you for paying {selectedAppl.feeType}
											</p>
										</div>
										<div className="col-md-6">
											<div className="invoice-footer-btn">
												<button
													id="print-button"
													type="button"
													onClick={() => window.print()}
													className="btn btn-primary-rgba py-1 font-16"
												>
													<i className="feather icon-printer mr-2" />
													Print
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	} catch (err) {
		return (
			<E500
				primaryColor={primaryColor}
				secondaryColor={secondaryColor}
				favicon={favicon}
				error={err}
			/>
		);
	}
};
