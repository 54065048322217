import React from "react";
import { useOnClickOutside } from "./lib/OnClickOutside";
import { E500 } from "../components/e500";
import { useDependencies } from "../store/dependencies";

export const Roles = ({
	userRoles,
	setUserRoles,
	roles,
	deCryptRole,
	deCryptRoleId,
	authData,
	primaryColor,
	secondaryColor,
	favicon,
	setDependency,
	unsetDependency,
	webSettings,
}) => {
	const authSess = JSON.parse(localStorage.getItem("authenticateSession"));
	const menuClose = React.useRef();
	useOnClickOutside(menuClose, () => setUserRoles(false));
	const appState = useDependencies((state) => state);
	const invalidateCache = (roleName, roleId) => {
		const stateVar = { ...appState };
		stateVar["dependeciesFormAPIS"] = {};
		authSess["role"] = roleName;
		authSess["roleId"] = roleId;
		unsetDependency(stateVar, roleName, roleId);
		setUserRoles(false);
		localStorage.setItem("authenticateSession", JSON.stringify(authSess));
		window.location.reload();
	};

	try {
		return (
			<>
				<div
					id="infobar-settings-sidebar"
					className={`infobar-settings-sidebar ${userRoles && "sidebarshow"}`}
					ref={menuClose}
				>
					<div className="infobar-settings-sidebar-head d-flex w-100 justify-content-between">
						<h4>User Roles</h4>
						<span
							onClick={() => setUserRoles(false)}
							className="infobar-settings-close cursor_Pointer"
						>
							<img
								src={require("../assets/images/svg-icon/close.svg").default}
								className="img-fluid menu-hamburger-close"
								alt="close"
							/>
						</span>
					</div>
					<div className="infobar-settings-sidebar-body">
						<div className="custom-layout-setting">
							<div className="row align-items-center pb-3">
								<div className="col-12">
									<h6 className="mb-3">Select Role</h6>
								</div>
								{authSess.session?.user?.userRoles.length &&
									authSess.session?.user?.userRoles.length > 0 &&
									authSess.session?.user?.userRoles.map((role, roleIndex) => (
										<div
											className="col-6 cursor_Pointer"
											key={roleIndex}
											onClick={() => {
												invalidateCache(role.roleName, role.roleId);
											}}
										>
											<div
												className={`account-box ${
													deCryptRole === role.roleName && "active"
												}`}
											>
												<img
													src={
														require("../assets/images/users/boy.svg").default
													}
													className="img-fluid"
													alt="user"
												/>
												<h5>{role.roleName}</h5>
											</div>
										</div>
									))}
							</div>
						</div>
					</div>
				</div>
				<div
					className={`infobar-settings-sidebar-overlay ${
						userRoles && "roleMenu"
					}`}
				/>
			</>
		);
	} catch (err) {
		return (
			<E500
				primaryColor={primaryColor}
				secondaryColor={secondaryColor}
				favicon={favicon}
				error={err}
			/>
		);
	}
};
