import React from "react";
import { default as dayjs } from "dayjs";
import { useForm } from "react-hook-form";

import { useCreateCall } from "../../lib/api/create";
import { useGetCall } from "../../lib/api/get";
import httpParams from "../../lib/httpParams";
import { timeAgo } from "../../lib/timeAgo";
import { E500 } from "../../components/e500";
import { MessageModal } from "./messageModal";

export const Messages = (
	{
		primaryColor,
		secondaryColor,
		favicon,
		appVariables,
		getData,
		authData,
		modSchema,
	},
	props
) => {
	React.useEffect(() => {
		document.getElementById("siteTitle").innerHTML = modSchema.pageTitle;
	});
	const params = httpParams.adminOrgParams(appVariables);
	const [isAdd, setIsAdd] = React.useState(false);
	const [activeSubjectIndex, setActiveSubjectIndex] = React.useState(0);
	const [autoRefresh, setAutoRefresh] = React.useState(false);

	const authSession = localStorage.getItem("authenticateSession")
		? JSON.parse(localStorage.getItem("authenticateSession"))
		: null;

	const getDatas = useGetCall(
		appVariables.REACT_APP_API_URL,
		modSchema.api,
		params,
		autoRefresh
	);

	const createCallMutation = useCreateCall();

	const { register, handleSubmit } = useForm({
		mode: "onSubmit",
	});

	const sendMessage = async (data) => {
		const payload = { ...data };

		if (getData.data.formData.length <= 0) {
			payload["subject"] = data.message;
		} else {
			payload["parentMessageId"] =
				getData.data.formData[activeSubjectIndex].UserMessageId;
			payload["subject"] = getData.data.formData[activeSubjectIndex].subject;
		}

		await createCallMutation.mutateAsync({
			url: appVariables.REACT_APP_API_URL,
			apiEndpoint: modSchema.api,
			data: payload, //data,
			messageTitle: modSchema.message.title,
			message: modSchema.message.message,
			params: params,
			noConfirmation: true,
		});
		setActiveSubjectIndex(activeSubjectIndex);
		document.getElementById("messaging").value = "";
	};

	const sendNewMessage = async (data) => {
		const payload = { ...data };
		if (data.message === "" || data.subject === "") {
			return;
		}

		await createCallMutation.mutateAsync({
			url: appVariables.REACT_APP_API_URL,
			apiEndpoint: modSchema.api,
			data: payload, //data,
			messageTitle: modSchema.message.title,
			message: modSchema.message.message,
			params: params,
			noConfirmation: true,
		});
		setIsAdd(false);
	};

	try {
		return (
			<div className="contentbar">
				<div className="row">
					<div className="col-6 form-group">
						Auto Refresh{" "}
						<input
							type="checkbox"
							onChange={() => setAutoRefresh(!autoRefresh)}
						/>
					</div>
					<div className="col-6 text-right form-group">
						<button
							className="btn btn-sm btn-primary"
							onClick={() => setIsAdd(true)}
						>
							New Message
						</button>
					</div>
				</div>
				<hr />
				<div className="row">
					<div className="col-md-5">
						<div className="chat-list">
							<div className="chat-user-list">
								<div className="slimScrollDiv">
									<ul className="list-unstyled mb-0">
										{getDatas.data.formData.length > 0
											? getDatas.data.formData
													.sort(
														(b, a) =>
															new Date(a.lastUpdatedDateTime) -
															new Date(b.lastUpdatedDateTime)
													)
													.map((message, messageIndex) => (
														<li
															key={messageIndex}
															className="media"
															onClick={() =>
																setActiveSubjectIndex(messageIndex)
															}
															style={{
																backgroundColor:
																	messageIndex === activeSubjectIndex
																		? "#F1F2F4"
																		: "",
															}}
														>
															<img
																src={
																	require("../../assets/images/users/profile.svg")
																		.default
																}
																alt="imgmsg"
															/>
															<div className="media-body">
																<h5>{message.subject}</h5>
																<p>
																	<i className="fa fa-circle chat-offline" />{" "}
																	{timeAgo(
																		new Date(
																			dayjs(message.lastUpdatedDateTime).format(
																				"MM-DD-YYYY h:mm A"
																			)
																		)
																	)}
																</p>
															</div>
														</li>
													))
											: "No Messages"}
									</ul>
								</div>
							</div>
						</div>
					</div>
					<div className="col-md-7">
						<div className="chat-detail">
							<div className="chat-head">
								<ul className="list-unstyled mb-0">
									<li className="media">
										<img
											src={
												require("../../assets/images/users/profile.svg").default
											}
											className="align-self-center mr-3 rounded-circle"
											alt="imgmsg"
											style={{ width: "3rem" }}
										/>
										<div className="media-body">
											{getDatas.data.formData.length > 0 && (
												<>
													<h5 className="font-18">
														{getDatas.data.formData[activeSubjectIndex].subject}
													</h5>
													<p className="mb-0">
														{getDatas.data.formData[activeSubjectIndex].message}
													</p>
												</>
											)}
										</div>
									</li>
								</ul>
							</div>
							<div className="slimScrollDiv">
								<div className="chat-body">
									{getDatas.data.formData.length > 0 && (
										<div
											className={`chat-message chat-message-${
												getDatas.data.formData[activeSubjectIndex].userId ===
												authSession.session.user.userId
													? "right"
													: "left"
											}`}
										>
											<div className="chat-message-text">
												{getDatas.data.formData[activeSubjectIndex].message}
											</div>
											<div className="chat-message-meta">
												<span>
													{timeAgo(
														new Date(
															dayjs(
																getDatas.data.formData[activeSubjectIndex]
																	.lastUpdatedDateTime
															).format("MM-DD-YYYY h:mm A")
														)
													)}
													<i className="feather icon-check ml-2"></i>
												</span>
											</div>
										</div>
									)}
									{getDatas.data.formData.length > 0 ? (
										getDatas.data.formData[activeSubjectIndex].childMessages
											.sort(
												(b, a) =>
													new Date(b.lastUpdatedDateTime) -
													new Date(a.lastUpdatedDateTime)
											)
											.map((message, messageIndex) => (
												<div
													key={`${messageIndex}${activeSubjectIndex}`}
													className={`chat-message chat-message-${
														message.userId === authSession.session.user.userId
															? "right"
															: "left"
													}`}
												>
													<div className="chat-message-text">
														{message.message}
													</div>
													<div className="chat-message-meta">
														<span>
															{timeAgo(
																new Date(
																	dayjs(message.lastUpdatedDateTime).format(
																		"MM-DD-YYYY h:mm A"
																	)
																)
															)}
															<i className="feather icon-check ml-2"></i>
														</span>
													</div>
												</div>
											))
									) : (
										<div className={`chat-message chat-message-left`}>
											<div className="chat-message-text">No Messages</div>
										</div>
									)}
								</div>
							</div>
							<div className="chat-bottom">
								<div className="chat-messagebar">
									<form onSubmit={handleSubmit(sendMessage)}>
										<div className="input-group">
											<input
												type="text"
												id="messaging"
												className="form-control"
												placeholder="Type a message..."
												aria-label="Text"
												{...register("message")}
											/>
											<div className="input-group-append">
												<button
													className="btn btn-primary-rgba"
													type="submit"
													id="button-addonsend"
												>
													<i className="feather icon-send" />
												</button>
											</div>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
				{isAdd && (
					<MessageModal
						appVariables={appVariables}
						setIsAdd={setIsAdd}
						handleSubmit={handleSubmit}
						sendMessage={sendNewMessage}
						register={register}
						createCallMutation={createCallMutation}
						primaryColor={primaryColor}
						secondaryColor={secondaryColor}
						favicon={favicon}
					/>
				)}
			</div>
		);
	} catch (err) {
		return (
			<E500
				primaryColor={primaryColor}
				secondaryColor={secondaryColor}
				favicon={favicon}
				error={err}
			/>
		);
	}
};
