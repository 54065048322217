import React from "react";
import _ from "lodash";
import httpParams from "../../../lib/httpParams";
import { getDependencyCall } from "../../../lib/api/dependencies";
import { useDependencies } from "../../../store/dependencies";
import { useAuthStore } from "../../../store/auth";
import { default as dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { E500 } from "../../../components/e500";
import { AttendanceCompAttendance } from "./attendanceCompAttendance";
import { useGetMutateCall } from "../../../lib/api/get";

import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

dayjs.extend(utc);
dayjs.extend(timezone);

export const AttendanceReport = ({
	primaryColor,
	secondaryColor,
	favicon,
	appVariables,
	getData,
	authData,
	modSchema,
}) => {
	const params = httpParams.adminOrgParams(appVariables);
	const getCallMutation = useGetMutateCall();

	const [selectedApplData, setSelectedApplData] = React.useState({
		data: { formData: [] },
	});
	const [gettingData, setGettingData] = React.useState(false);
	const [monthVar, setMonthVar] = React.useState(null);
	const [classVar, setClassVar] = React.useState(null);
	const [wrkDaysVar, setWrkDaysVar] = React.useState(null);

	const appState = useAuthStore((state) => state);
	const { setDependency } = useDependencies();

	React.useEffect(() => {
		document.getElementById("siteTitle").innerHTML = modSchema.pageTitle;

		const stateVar = { ...appState };

		if (modSchema.hasDependencies) {
			modSchema.dependeciesFormAPIS.map(async (api) => {
				if (!stateVar.dependeciesFormAPIS[api]) {
					stateVar.dependeciesFormAPIS[api] = await getDependencyCall(
						appVariables.REACT_APP_API_URL,
						api,
						params
					);
					setDependency(stateVar, stateVar.role, stateVar.roleId);
				}
			});
		}
	});

	const getYears = () => {
		var currentYear = new Date().getFullYear();
		const yearList = [];
		for (var i = 1950; i <= currentYear; i++) {
			yearList.push(i);
		}
		return yearList;
	};

	const getMonths = () => {
		const monthList = [
			{ name: "JAN", id: 1 },
			{ name: "FEB", id: 2 },
			{ name: "MAR", id: 3 },
			{ name: "APR", id: 4 },
			{ name: "MAY", id: 5 },
			{ name: "JUN", id: 6 },
			{ name: "JUL", id: 7 },
			{ name: "AUG", id: 8 },
			{ name: "SEP", id: 9 },
			{ name: "OCT", id: 10 },
			{ name: "NOV", id: 11 },
			{ name: "DEC", id: 12 },
		];

		return monthList;
	};

	function getMonthlyReport(attenData, yea, mont) {
		const year = dayjs(monthVar).format("YYYY");
		const month = dayjs(monthVar).format("MM");
		const lastDayOfMonth = new Date(year, month, 0).getDate();
		var days = [];
		var weekday = new Array(7);
		weekday[0] = "Sun";
		weekday[1] = "Mon";
		weekday[2] = "Tues";
		weekday[3] = "Wed";
		weekday[4] = "Thu";
		weekday[5] = "Fri";
		weekday[6] = "Sat";

		for (let i = 1; i < lastDayOfMonth + 1; i++) {
			let ateendance = null;
			let attendanceData = {};

			if (attenData.length > 0) {
				attenData.map((a) => {
					if (a.attends.length > 0) {
						a.attends.map((at) =>
							dayjs(at.checkInDateTime).format("DD-MM-YYYY") ===
							dayjs(new Date(year, month - 1, i).toDateString()).format(
								"DD-MM-YYYY"
							)
								? (ateendance = at.attendanceType)
								: null
						);
					}
				});

				attenData.map((a) => {
					if (a.attends.length > 0) {
						a.attends.map((at) =>
							dayjs(at.checkInDateTime).format("DD-MM-YYYY") ===
							dayjs(new Date(year, month - 1, i).toDateString()).format(
								"DD-MM-YYYY"
							)
								? (attendanceData = at)
								: null
						);
					}
				});
			}

			const str = {
				date: new Date(year, month - 1, i).toDateString(),
				day: new Date(year, month - 1, i).getDay(),
				ateendance: ateendance,
				attendanceData: attendanceData,
			};
			days.push({ ...str, weekdays: weekday });
		}

		const regAtten = [];

		attenData.map((a) => {
			a.attends.map((at) => {
				regAtten.push({
					att: at.PickupPermission.userChildName,
					daysAtten: [],
				});
			});
		});

		// days.map((d) =>
		// 	attenData.map((a) =>
		// 		a.attends.map((at) => {
		// 			if (
		// 				dayjs(d.date).format("DD-MM-YYYY") ===
		// 				dayjs(at.checkInDateTime).format("DD-MM-YYYY")
		// 			) {
		// 				regAtten.push({ aten: at });
		// 			} else {
		// 				regAtten.push({ aten: null });
		// 			}
		// 		})
		// 	)
		// );
		const reggg = [];
		attenData.map((m) => {
			days.map((d) => {
				reggg.push({ m, attend: d });
			});
		});

		console.log(days, attenData, reggg);

		let cps = 0,
			cabs = 0;
		for (let i = 0; i < days.length; i++) {
			if (days[i].day > 0 && days[i].ateendance === null) {
				cabs = cabs + 1;
			} else if (days[i].day > 0) {
				cps = cps + 1;
			}
		}

		let percentAtt = (cps / days.length) * 100;

		// for (let i = 1; i < lastDayOfMonth + 1; i++) {
		// 	let ateendance = null;
		// 	let attendanceData = {};

		// 	if (attenData.applicationId.length > 0) {
		// 		attenData.applicationId.map((a) =>
		// 			dayjs(a.checkInDateTime).format("DD-MM-YYYY") ===
		// 			dayjs(new Date(year, month - 1, i).toDateString()).format(
		// 				"DD-MM-YYYY"
		// 			)
		// 				? (ateendance = a.attendanceType)
		// 				: null
		// 		);
		// 		attenData.applicationId.map((a) =>
		// 			dayjs(a.checkInDateTime).format("DD-MM-YYYY") ===
		// 			dayjs(new Date(year, month - 1, i).toDateString()).format(
		// 				"DD-MM-YYYY"
		// 			)
		// 				? (attendanceData = a)
		// 				: null
		// 		);
		// 	}
		// 	const str = {
		// 		date: new Date(year, month - 1, i).toDateString(),
		// 		day: new Date(year, month - 1, i).getDay(),
		// 		ateendance: ateendance,
		// 		attendanceData: attendanceData,
		// 	};
		// 	days.push({ ...str, weekdays: weekday });
		// }
		// const firstDay = new Date(year, month - 1, 1).getDay();
		// const lastDay = new Date(year, month, 0).getDay();

		// const emptyDays = [];
		// const emptyDaysEnd = [];
		// for (let i = 0; i < firstDay; i++) {
		// 	emptyDays.push({ isEmpty: true });
		// }
		// for (let i = 0; i < 6 - lastDay; i++) {
		// 	emptyDaysEnd.push({ isEmpty: true });
		// }
		// const returnData = [...emptyDays, ...days, ...emptyDaysEnd];
		// let cps = 0,
		// 	cabs = 0;
		// for (let i = 0; i < days.length; i++) {
		// 	if (days[i].day > 0 && days[i].ateendance === null) {
		// 		cabs = cabs + 1;
		// 	} else if (days[i].day > 0) {
		// 		cps = cps + 1;
		// 	}
		// }

		// let percentAtt = (cps / days.length) * 100;
		// const monthlyDays = [];
		// const monthlyAttenReg = [];
		// for (let i = 0; i < days.length; i++) {
		// 	attenData.applicationId.map((a) => {
		// 		if (
		// 			dayjs(a.checkInDateTime).format("DD-MM-YYYY") ===
		// 			dayjs(days[i].date).format("DD-MM-YYYY")
		// 		) {
		// 			monthlyAttenReg.push({ dayInfo: days[i], attenInfo: a });
		// 		}
		// 	});
		// }
		// monthlyDays.push(
		// 	{ name: attenData.applicationId[0].PickupPermission.userChildName },
		// 	...days,
		// 	{ present: cps },
		// 	{ absent: cabs },
		// 	{ total: cabs + cps },
		// 	{ attenPercent: percentAtt.toFixed(2) }
		// );

		// // const dayChunks = [];
		// // for (let i = 0; i < returnData.length; i += 7) {
		// // 	const chunk = returnData.slice(i, i + 7);
		// // 	dayChunks.push(chunk);
		// // }
		// // const totalDays = [...dayChunks];
		// console.log(monthlyAttenReg, days);
		// return monthlyDays;
	}

	function daysInThisMonth(attenData, yea, mont) {
		const year = dayjs(attenData.applicationId[0].checkInDateTime).format(
			"YYYY"
		);
		const month = dayjs(attenData.applicationId[0].checkInDateTime).format(
			"MM"
		);
		console.log(monthVar, dayjs(monthVar).format("YYYY"));
		const lastDayOfMonth = new Date(year, month, 0).getDate();
		var days = [];
		var weekday = new Array(7);
		weekday[0] = "Sun";
		weekday[1] = "Mon";
		weekday[2] = "Tues";
		weekday[3] = "Wed";
		weekday[4] = "Thu";
		weekday[5] = "Fri";
		weekday[6] = "Sat";
		for (let i = 1; i < lastDayOfMonth + 1; i++) {
			let ateendance = null;
			let attendanceData = {};

			if (attenData.applicationId.length > 0) {
				attenData.applicationId.map((a) =>
					dayjs(a.checkInDateTime).format("DD-MM-YYYY") ===
					dayjs(new Date(year, month - 1, i).toDateString()).format(
						"DD-MM-YYYY"
					)
						? (ateendance = a.attendanceType)
						: null
				);
				attenData.applicationId.map((a) =>
					dayjs(a.checkInDateTime).format("DD-MM-YYYY") ===
					dayjs(new Date(year, month - 1, i).toDateString()).format(
						"DD-MM-YYYY"
					)
						? (attendanceData = a)
						: null
				);
			}
			const str = {
				date: new Date(year, month - 1, i).toDateString(),
				day: new Date(year, month - 1, i).getDay(),
				ateendance: ateendance,
				attendanceData: attendanceData,
			};
			days.push({ ...str, weekdays: weekday });
		}
		const firstDay = new Date(year, month - 1, 1).getDay();
		const lastDay = new Date(year, month, 0).getDay();

		const emptyDays = [];
		const emptyDaysEnd = [];
		for (let i = 0; i < firstDay; i++) {
			emptyDays.push({ isEmpty: true });
		}
		for (let i = 0; i < 6 - lastDay; i++) {
			emptyDaysEnd.push({ isEmpty: true });
		}
		const returnData = [...emptyDays, ...days, ...emptyDaysEnd];
		const monthlyDays = [
			{ name: attenData.applicationId[0].PickupPermission.userChildName },
			...days,
		];
		console.log("DAYS: ", days);
		// const dayChunks = [];
		// for (let i = 0; i < returnData.length; i += 7) {
		// 	const chunk = returnData.slice(i, i + 7);
		// 	dayChunks.push(chunk);
		// }
		// const totalDays = [...dayChunks];
		return monthlyDays;
	}

	const getOnchangeAppl = async (e) => {
		setGettingData(true);
		await getCallMutation
			.mutateAsync({
				apiURL: appVariables.REACT_APP_API_URL,
				apiEndpoint: "attendance",
				params: params + "&applicationId=" + e,
			})
			.then((resp) => {
				setSelectedApplData({ data: resp });
			});
		setGettingData(false);
	};

	const groupBy = (array, key) => {
		const groups = _.groupBy(array, key);
		const attends = [];
		Object.entries(groups).map(([key, value]) => {
			const kArr = [];
			kArr.push({ attens: value });
			console.log(value);
			if (value.length > 0) {
				attends.push({
					attends: value,
				});
			}
		});
		console.log(attends);
		return attends;
		// return array.reduce((result, currentValue) => {
		// 	(result[key] = result[key] || []).push(currentValue);
		// 	return result;
		// }, {});

		//return groups;
	};

	const handleSelect = async (e) => {
		e.preventDefault();
		let formData = new FormData(e.currentTarget);
		const data = Object.fromEntries(formData);
		const fDay = dayjs(monthVar).startOf("month").format("YYYY-MM-DD");
		const lDay = dayjs(monthVar).endOf("month").format("YYYY-MM-DD");

		// let fromD = null,
		// 	toD = null;
		// if (e.target.name === "fromDate") {
		// 	fromD = e.target.value;
		// 	setState({ startDate: e.target.value, endDate: state.endDate });
		// 	setEndDate(e.target.value);
		// }
		// if (e.target.name === "toDate") {
		// 	toD = e.target.value;
		// 	setState({ endDate: e.target.value, startDate: state.startDate });
		// 	setStartDate(e.target.value);
		// }
		// console.log(startDate, endDate);
		if (JSON.parse(data.class).cId !== "Select Class" && monthVar !== null) {
			setGettingData(true);
			setClassVar(data.class);
			await getCallMutation
				.mutateAsync({
					apiURL: appVariables.REACT_APP_API_URL,
					apiEndpoint: "attendance",
					params:
						params +
						"&classId=" +
						JSON.parse(data.class).cId +
						"&checkInDateTimeFrom=" +
						dayjs.utc(fDay).format("YYYY-MM-DD 00:00:00") +
						"&checkInDateTimeTo=" +
						dayjs.utc(lDay).format("YYYY-MM-DD 23:59:59"),
				})
				.then((resp) => {
					setSelectedApplData({ data: resp });
				});
			setGettingData(false);
		}
	};
	try {
		return (
			<div className="contentbar">
				<div className="">
					<form onSubmit={(e) => handleSelect(e)} className="row">
						<div className="col-md-5 form-group">
							<select className="form-control" name="class">
								<option title="S C">Select Class</option>
								{authData.dependeciesFormAPIS["classes"]?.formData.length > 0 &&
									authData.dependeciesFormAPIS["classes"]?.formData.map(
										(ops, opsInd) => (
											<option
												key={opsInd}
												value={JSON.stringify({
													cId: ops.classId,
													cName: ops.className,
												})}
											>
												{ops.className}
											</option>
										)
									)}
							</select>
						</div>
						<div className="col-md-5 form-group">
							<input
								className="form-control"
								onChange={(e) => setMonthVar(e.target.value)}
								type="month"
								name="month"
							/>
						</div>
						<div className="col-md-2 form-group">
							<button type="submit" className="btn btn-primary">
								<i className="fa fa-search"></i>
							</button>
						</div>
					</form>
					<hr />
				</div>
				{gettingData && (
					<div className="row">
						<div className="calendar-container">
							<div className="calendar">
								<div className="year-header">
									<span className="year" id="label">
										Loading...
									</span>
								</div>
							</div>
						</div>
					</div>
				)}
				{gettingData !== true && selectedApplData.data.formData.length > 0 && (
					<div className="table-responsive">
						<table className="table table-bordered">
							<tbody>
								{/* <tr className="mb-1 mt-1">
									<td
										colSpan={
											getMonthlyReport(
												groupBy(selectedApplData.data.formData, "applicationId")
											).length
										}
									>
										<table
											style={{
												border: "none",
												width: "-webkit-fill-available",
											}}
										>
											<tr>
												<td>
													Class:{" "}
													{classVar !== null && JSON.parse(classVar).cName}{" "}
												</td>
												<td>Month: {dayjs(monthVar).format("MMMM, YYYY")}</td>
												<td>
													Working Days:{" "}
													{
														getMonthlyReport(
															groupBy(
																selectedApplData.data.formData,
																"applicationId"
															)
														)[
															getMonthlyReport(
																groupBy(
																	selectedApplData.data.formData,
																	"applicationId"
																)
															).length - 2
														].total
													}
												</td>
											</tr>
										</table>
									</td>
								</tr> */}
								{/* <tr>
									{getMonthlyReport(
										groupBy(selectedApplData.data.formData, "applicationId")
									).map((child, childIndex) => (
										<td key={childIndex} className="b-all p-1">
											<h6>
												{child.date
													? dayjs(child.date).format("D")
													: child.name
													? "Name"
													: child.present
													? "P"
													: child.absent
													? "A"
													: child.total
													? "T"
													: "%"}
											</h6>
										</td>
									))}
								</tr>
								<tr>
									{getMonthlyReport(
										groupBy(selectedApplData.data.formData, "applicationId")
									).map((child, childIndex) => (
										<td
											key={childIndex}
											className={`b-all p-1 ${
												child.day === 0 ? "bg-danger" : ""
											}`}
										>
											{child.name
												? child.name
												: child.day === 0
												? ""
												: child.ateendance === null
												? "A"
												: child.ateendance === 0
												? "P"
												: child.present
												? child.present
												: child.absent
												? child.absent
												: child.total || child.attenPercent}
										</td>
									))}
								</tr> */}
								{console.log(
									getMonthlyReport(
										groupBy(selectedApplData.data.formData, "applicationId")
									)
								)}
								<tr>
									{selectedApplData.data.formData.length > 0 &&
										selectedApplData.data.formData.map((at, atInd) => (
											<td key={atInd}>{at.PickupPermission.userChildName}</td>
										))}
								</tr>
							</tbody>
						</table>
					</div>
				)}
			</div>
		);
	} catch (err) {
		return (
			<E500
				primaryColor={primaryColor}
				secondaryColor={secondaryColor}
				favicon={favicon}
				error={err}
			/>
		);
	}
};
