import axios from "axios";

var uri = "http://localhost:8004/mfs100/";

export const captureFinger = (quality, timeout, setFPError, setFPResponse) => {
	var MFS100Request = {
		Quality: quality,
		TimeOut: timeout,
	};
	var jsondata = JSON.stringify(MFS100Request);
	return postMFS100Client("capture", jsondata, setFPError, setFPResponse);
};

export const capAndMatch = async (
	quality,
	timeout,
	fpString,
	setFPError,
	setFPResponse
) => {
	var MFS100Request = {
		Quality: quality,
		TimeOut: timeout,
		GalleryTemplate: fpString,
		BioType: "FMR", // you can paas here BioType as "ANSI" if you are using ANSI Template
	};
	var jsondata = JSON.stringify(MFS100Request);
	const res = await postMFS100Client(
		"match",
		jsondata,
		setFPError,
		setFPResponse
	);
	return res;
};

export const verifyFinger = (ProbFMR, GalleryFMR) => {
	var MFS100Request = {
		ProbTemplate: ProbFMR,
		GalleryTemplate: GalleryFMR,
		BioType: "FMR", // you can paas here BioType as "ANSI" if you are using ANSI Template
	};
	var jsondata = JSON.stringify(MFS100Request);
	return postMFS100Client("verify", jsondata);
};

const postMFS100Client = async (
	method,
	jsonData,
	setFPError,
	setFPResponse
) => {
	if (method === "capture") {
		await axios
			.post(uri + method, jsonData, {
				headers: { "Content-Type": "application/json; charset=utf-8" },
			})
			.then((res) => {
				if (res.data.ErrorCode === "0") {
					localStorage.setItem("mfsFP", res.data.IsoTemplate);
					setFPResponse("success");
				} else {
					setFPError(true);
					setFPResponse("error");
				}
			})
			.catch((err) => {
				setFPResponse("error");
				setFPError(true);
			});
	}

	if (method === "match") {
		let resp = null;
		await axios
			.post(uri + method, jsonData, {
				headers: { "Content-Type": "application/json; charset=utf-8" },
			})
			.then((res) => {
				if (res.data.Status) {
					setFPResponse("success");
					return (resp = 1);
				} else {
					setFPError(true);
					setFPResponse("error");
					return (resp = 0);
				}
			})
			.catch((err) => {
				setFPResponse("error");
				setFPError(true);
				return (resp = 0);
			});
		return resp;
	}
};

/////////// Classes

function Biometric(BioType, BiometricData, Pos, Nfiq, Na) {
	this.BioType = BioType;
	this.BiometricData = BiometricData;
	this.Pos = Pos;
	this.Nfiq = Nfiq;
	this.Na = Na;
}

function MFS100Request(BiometricArray) {
	this.Biometrics = BiometricArray;
}
