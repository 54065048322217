import { useQuery, useMutation } from "react-query";
import { axios } from "../react-query/axios_get";

export const getCall = (apiURL, apiEndpoint, params) => {
	return axios.get(apiURL + "/" + apiEndpoint + params);
};

export const useGetCall = (
	apiURL,
	apiEndpoint,
	params,
	doPolling,
	enabled = true,
	{ config } = {}
) => {
	return useQuery({
		...config,
		refetchInterval: doPolling ? 30000 : false,
		queryKey: [apiEndpoint],
		queryFn: () => getCall(apiURL, apiEndpoint, params),
		enabled: enabled,
	});
};

export const useGetDocsCall = (call, { config } = {}) => {
	return useMutation(
		async (call) => await getCall(call.apiURL, call.apiEndpoint, call.params),
		{
			onError: (_, __, context) => {},
			onSuccess: () => {},
			...config,
			mutationFn: () => getCall,
		}
	);
};

export const useGetMutateCall = (call, { config } = {}) => {
	return useMutation(
		async (call) =>
			await getCall(call.apiURL, call.apiEndpoint, call.params, call.doPolling),
		{
			onError: (_, __, context) => {},
			onSuccess: () => {},
			...config,
			mutationFn: () => getCall,
		}
	);
};

export const useGetDocsCalls = (
	apiURL,
	apiEndpoint,
	params,
	doPolling,
	{ config } = {}
) => {
	return useQuery({
		...config,
		refetchInterval: doPolling ? 30000 : false,
		queryKey: [apiEndpoint],
		queryFn: () => getCall(apiURL, apiEndpoint, params),
	});
};

export const useGetPageCall = (
	apiURL,
	apiEndpoint,
	id,
	page,
	params,
	enabled,
	{ config } = {}
) => {
	let pName = page.split(" ").join("");
	return useQuery({
		...config,
		queryKey: [apiEndpoint],
		enabled: enabled,
		queryFn: () =>
			getCall(apiURL, apiEndpoint + "/" + id, params + "&pageName=" + pName),
	});
};

export const useGetSPAPageCall = (
	apiURL,
	apiEndpoint,
	page,
	params,
	homePage,
	{ config } = {}
) => {
	return useQuery({
		...config,
		queryKey: "pageSPA",
		queryFn: () =>
			getCall(apiURL, apiEndpoint, params + "&pageName=" + homePage),
	});
};
