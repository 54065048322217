import React from "react";

const authent =
	localStorage.getItem("authent") &&
	JSON.parse(localStorage.getItem("authent"));
const authenticateSession =
	localStorage.getItem("authenticateSession") &&
	JSON.parse(localStorage.getItem("authenticateSession"));
const tzOffset = new Date();
const httpParams = {
	uiParams: (appVariables) => {
		return `?tenantId=${appVariables.REACT_APP_TENANT_ID}&appId=${
			appVariables.REACT_APP_APP_ID
		}&publishStatus=PREVIEW&timeZone=${tzOffset.getTimezoneOffset()}`;
	},
	adminParams: (appVariables) => {
		return `?tenantId=${appVariables.REACT_APP_TENANT_ID}&appId=${
			appVariables.REACT_APP_APP_ID
		}&roleId=${
			authenticateSession?.roleId && authenticateSession?.roleId
		}&timeZone=${tzOffset.getTimezoneOffset()}`;
	},
	adminOrgParams: (appVariables) => {
		return `?tenantId=${appVariables.REACT_APP_TENANT_ID}&appId=${
			appVariables.REACT_APP_APP_ID
		}&orgId=${appVariables.REACT_APP_ORG_ID}&roleId=${
			authenticateSession?.roleId && authenticateSession?.roleId
		}&timeZone=${tzOffset.getTimezoneOffset()}`;
	},
};

export default httpParams;
