import React from "react";

import * as uiHelper from "../lib/uihelper";
import "../assets/css/bootstrap.min.css";
import "../assets/css/icons.css";
import "../assets/css/flag-icon.min.css";
import "../assets/css/style.css";
import "../assets/css/custom.css";
//import "../assets/index.css";

import { ModalLogin } from "./modal";
import { Roles } from "./roles";
import { LeftBar } from "./leftBar";
import { TopBar } from "./topBar";
// import { Footer } from "./footer";
import { Content } from "./content";
import { E500 } from "../components/e500";
import { NoConnection } from "./lib/noConnection";
import { useDependencies } from "../store/dependencies";

export const ModuleLayout = ({
	primaryColor,
	secondaryColor,
	favicon,
	authData,
	userLogout,
	routesData,
	appVariables,
	modSchema,
	routeDetails,
	isOnline,
	webSettings,
}) => {
	uiHelper.mainUILoad(primaryColor, secondaryColor, favicon);

	const [userRoles, setUserRoles] = React.useState(false);
	const [loginModal, setLoginModal] = React.useState(false);
	const [mainCrudAdd, setMainCrudAdd] = React.useState(false);
	const [isSideMenuLarge, setIsSideMenuLarge] = React.useState(true);
	const authSession = localStorage.getItem("authenticateSession") || null;

	const { setDependency, unsetDependency } = useDependencies();
	document.body.classList.add("vertical-layout");
	document.body.classList.remove("horizontal-layout");

	try {
		return (
			<div>
				<Roles
					userRoles={userRoles}
					setUserRoles={setUserRoles}
					roles={
						JSON.parse(localStorage.getItem("authenticateSession"))?.session
							?.user.userRoles
					}
					deCryptRole={
						JSON.parse(localStorage.getItem("authenticateSession"))?.role
					}
					deCryptRoleId={
						JSON.parse(localStorage.getItem("authenticateSession"))?.roleId
					}
					authData={authData}
					primaryColor={primaryColor}
					secondaryColor={secondaryColor}
					favicon={favicon}
					setDependency={setDependency}
					unsetDependency={unsetDependency}
					webSettings={webSettings}
				/>
				<div id="containerbar">
					<LeftBar
						authData={authData}
						primaryColor={primaryColor}
						secondaryColor={secondaryColor}
						favicon={favicon}
						userLogout={userLogout}
						routesData={routesData}
						appVariables={appVariables}
						authSession={JSON.parse(
							localStorage.getItem("authenticateSession")
						)}
						isSideMenuLarge={isSideMenuLarge}
						webSettings={webSettings}
					/>
					<div className="rightbar">
						<TopBar
							primaryColor={primaryColor}
							secondaryColor={secondaryColor}
							favicon={favicon}
							setTopBarMenu={uiHelper.setTopBarMenu}
							setSideMenu={uiHelper.setSideMenu}
							setSideMenuMobile={uiHelper.setSideMenuMobile}
							openModal={uiHelper.openModal}
							setLoginModal={setLoginModal}
							setUserRoles={setUserRoles}
							userLogout={userLogout}
							authSession={JSON.parse(authSession)}
							appVariables={appVariables}
							setIsSideMenuLarge={setIsSideMenuLarge}
							isSideMenuLarge={isSideMenuLarge}
						/>
						{isOnline === true ? (
							<Content
								key={modSchema}
								uiHelper={uiHelper}
								setMainCrudAdd={setMainCrudAdd}
								mainCrudAdd={mainCrudAdd}
								modSchema={modSchema}
								appVariables={appVariables}
								primaryColor={primaryColor}
								secondaryColor={secondaryColor}
								favicon={favicon}
								authData={authData}
								routeDetails={routeDetails}
								userLogout={userLogout}
								isOnline={isOnline}
							/>
						) : (
							<div className="breadcrumbbar">
								<div className="row align-items-center">
									<div className="col-md-3"></div>
									<div className="col-md-6 text-center">
										<div className="d-inline">
											<NoConnection
												primaryColor={primaryColor}
												secondaryColor={secondaryColor}
												favicon={favicon}
											/>
										</div>
									</div>
									<div className="col-md-3"></div>
								</div>
								<div className="row align-items-center">
									<div className="col-md-12 text-center">
										<h4 className="page-title">No Internet Connection</h4>
									</div>
								</div>
								<div className="row align-items-center">
									<div className="col-md-12 text-center">
										<p className="page-title">
											Please check your internet connection and try again
										</p>
										<button className="btn btn-primary mt-2">
											<i className="fa fa-refresh" /> Refresh
										</button>
									</div>
								</div>
							</div>
						)}
						{/* <Footer /> */}
					</div>
				</div>
				{loginModal && (
					<ModalLogin
						closeModal={uiHelper.closeModal}
						setLoginModal={setLoginModal}
						primaryColor={primaryColor}
						secondaryColor={secondaryColor}
						favicon={favicon}
					/>
				)}
			</div>
		);
	} catch (err) {
		return (
			<E500
				primaryColor={primaryColor}
				secondaryColor={secondaryColor}
				favicon={favicon}
				error={err}
			/>
		);
	}
};
