import React from "react";
import { E500 } from "../../components/e500";
import { Form } from "../../components/form";
import { Link } from "react-router-dom";

export const Profile = ({
	primaryColor,
	secondaryColor,
	favicon,
	authData,
	modSchema,
	appVariables,
	userLogout,
}) => {
	const [editProfile, setEditProfile] = React.useState(false);
	const userSession = localStorage.getItem("authenticateSession")
		? JSON.parse(localStorage.getItem("authenticateSession"))
		: null;
	const userProfile =
		userSession !== null ? userSession.session.user.UserFamily[0] : null;

	try {
		return (
			<div className="contentbar">
				<div className="row">
					<div className="col-12">
						<div className="tab-content" id="v-pills-tabContent">
							<div
								className="tab-pane fade show active"
								id="v-pills-dashboard"
								role="tabpanel"
								aria-labelledby="v-pills-dashboard-tab"
							>
								<div className="card m-b-30">
									<div className="card-body">
										<div className="profilebox py-4 text-center">
											<img
												src={
													authData.authentication.profileImg === null
														? require("../../assets/images/users/profile.svg")
																.default
														: authData.authentication.profileImg
												}
												className="img-fluid"
												alt="profile"
											/>
											<div className="profilename">
												<h5>{authData.authentication.name}</h5>
												<p className="text-muted">
													{authData.authentication.email}
												</p>
											</div>
											<div className="button-list">
												<span
													className="btn btn-primary-rgba font-18 cursor_pointer active"
													onClick={() => setEditProfile(!editProfile)}
												>
													<i className="feather icon-edit-2" />
												</span>

												<Link
													to="/console/my-messages"
													className="btn btn-info-rgba font-18"
												>
													<i className="feather icon-message-square" />
												</Link>
												<span
													onClick={() => userLogout()}
													className="btn btn-danger-rgba font-18"
												>
													<i className="feather icon-log-out" />
												</span>
											</div>
											{editProfile && (
												<div className="text-left">
													<h5 className="card-title">
														{modSchema.form.update.formTitle}
													</h5>
													<Form
														defaultData={userProfile}
														formSchema={modSchema.form.update}
														appVariables={appVariables}
														addUserObject={true}
														authData={userSession}
														modSchema={modSchema}
														primaryColor={primaryColor}
														secondaryColor={secondaryColor}
														favicon={favicon}
													/>
												</div>
											)}
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-lg-12 col-xl-4">
										<div className="card m-b-20">
											<div className="card-body">
												<div className="ecom-dashboard-widget">
													<div className="media">
														<i className="feather icon-package" />
														<div className="media-body">
															<h5>My Family</h5>
															<p>{`${
																authData.dependeciesFormAPIS.userFamily
																	?.formData?.length
																	? authData.dependeciesFormAPIS.userFamily
																			?.formData?.length
																	: 0
															} Members`}</p>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="col-lg-12 col-xl-4">
										<div className="card m-b-20">
											<div className="card-body">
												<div className="ecom-dashboard-widget">
													<div className="media">
														<i className="feather icon-heart" />
														<div className="media-body">
															<h5>My Kids</h5>
															<p>
																{`${
																	authData.dependeciesFormAPIS.userChildren
																		?.formData?.length
																		? authData.dependeciesFormAPIS.userChildren
																				?.formData?.length
																		: 0
																}`}{" "}
																Kids
															</p>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="col-lg-12 col-xl-4">
										<div className="card m-b-20">
											<div className="card-body">
												<div className="ecom-dashboard-widget">
													<div className="media">
														<i className="feather icon-credit-card" />
														<div className="media-body">
															<h5>My Applications</h5>
															<p>{`${
																authData.dependeciesFormAPIS.appl?.formData
																	?.length
																	? authData.dependeciesFormAPIS.appl?.formData
																			?.length
																	: 0
															} Applications`}</p>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	} catch (err) {
		return (
			<E500
				primaryColor={primaryColor}
				secondaryColor={secondaryColor}
				favicon={favicon}
				error={err}
			/>
		);
	}
};
