import React from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import { AppProvider } from "./provider/app";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";

const isLocalEnvironment =
	process.env.REACT_APP_USE_API_URL_LOCAL === "1"
		? ""
		: process.env.REACT_APP_API_URL_LOCAL;

axios.get(isLocalEnvironment + "/api").then((resp) => {
	const envData = resp.data || {};

	//Prepare manifest file for PWA
	const manifestString = JSON.parse(envData.REACT_APP_MANIFEST);
	manifestString["start_url"] = window.location.origin;

	const manifestStringified = JSON.stringify(manifestString);

	const manifestBlob = new Blob([manifestStringified], {
		type: "application/json",
	});

	//Prepare manifest file for PWA
	const manifestURL = URL.createObjectURL(manifestBlob);

	//Assign manifest file for PWA
	const link = document.getElementById("appManifest");
	link.setAttribute("href", manifestURL);

	//Assign favicon
	const favLink = document.getElementById("faviconLink");
	favLink.setAttribute("href", envData.REACT_APP_THEME_FAVICON);

	//Assign default site title
	const siteTitle = document.getElementById("siteTitle");
	siteTitle.innerHTML = envData.REACT_APP_PAGE_TITLE;

	//Assign theme color
	const themeColor = document.getElementById("themeColor");
	themeColor.setAttribute("content", envData.REACT_APP_THEME_COLOR);

	ReactDOM.render(
		<AppProvider>
			<App appVariables={resp.data} />
		</AppProvider>,
		document.getElementById("root")
	);

	// If you want your app to work offline and load faster, you can change
	// unregister() to register() below. Note this comes with some pitfalls.
	// Learn more about service workers: https://cra.link/PWA
	serviceWorkerRegistration.unregister();

	// If you want to start measuring performance in your app, pass a function
	// to log results (for example: reportWebVitals(console.log))
	// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
	reportWebVitals();
});
