import React from "react";
import { default as dayjs } from "dayjs";
import { E500 } from "../../components/e500";

export const AttendanceCompClass = ({
	submitAttendance,
	gettingData,
	appVariables,
	getData,
	appState,
	modSchema,
	primaryColor,
	secondaryColor,
	favicon,
}) => {
	const findAttendance = (data, ar2) => {
		const itm = ar2 && ar2.find((e) => e.applicationId === data.applicationId);

		if (itm && data.applicationId === itm.applicationId) {
			const today = dayjs(new Date()).format("MM-DD-YYYY");
			const attenDate = dayjs(itm.checkInDateTime).format("MM-DD-YYYY");

			if (today === attenDate) {
				if (itm.attendanceType === 5) {
					return (
						<div className="row">
							<div className="col-6" style={{ verticalAlign: "middle" }}>
								<h4 className="card-title" style={{ marginTop: "0.3rem" }}>
									{`${data.applicationNumber} - ${data.userChildName}`}
								</h4>
							</div>
							<div className="col-6 text-right" style={{ textAlign: "right" }}>
								<button
									type="button"
									title="Present"
									disabled
									className="btn btn-sm btn-success ms-auto float-end mr-2 text-white"
								>
									<i className="fa fa-check"></i>
								</button>
							</div>
						</div>
					);
				} else if (itm.attendanceType === 6) {
					return (
						<div className="row">
							<div className="col-6">
								<h4 className="card-title">
									{`${data.applicationNumber} - ${data.userChildName}`}
								</h4>
							</div>
							<div className="col-6 text-right" style={{ textAlign: "right" }}>
								<button
									type="button"
									title="Absent"
									disabled
									className="btn btn-sm btn-danger ms-auto float-end mr-2"
								>
									&nbsp;<i className="fa fa-times"></i>
								</button>
							</div>
						</div>
					);
				}
			} else {
				return (
					<div className="row">
						<div className="col-6">
							<h4 className="card-title">
								{`${data.applicationNumber} - ${data.userChildName}`}
							</h4>
						</div>
						<div className="col-6 text-right" style={{ textAlign: "right" }}>
							<button
								type="button"
								title="Absent"
								className="btn btn-sm btn-danger mr-2"
								onClick={() => submitAttendance(data, 6)}
							>
								&nbsp;<i className="fa fa-times"></i>
							</button>
							<button
								type="button"
								title="Present"
								className="btn btn-sm btn-primary"
								onClick={() => submitAttendance(data, 5)}
							>
								<i className="fa fa-check"></i>
							</button>
						</div>
					</div>
				);
			}
		} else {
			return (
				<div className="row">
					<div className="col-6">
						<h4 className="card-title">
							{`${data.applicationNumber} - ${data.userChildName}`}
						</h4>
					</div>
					<div className="col-6 text-right" style={{ textAlign: "right" }}>
						<button
							type="button"
							title="Absent"
							className="btn btn-sm btn-danger mr-2"
							onClick={() => submitAttendance(data, 6)}
						>
							&nbsp;<i className="fa fa-times"></i>
						</button>
						<button
							type="button"
							title="Present"
							className="btn btn-sm btn-primary"
							onClick={() => submitAttendance(data, 5)}
						>
							<i className="fa fa-check"></i>
						</button>
					</div>
				</div>
			);
		}
	};
	try {
		return (
			<div className="b-all border-2 rounded border_Width-1">
				<hr />
				<div id="header-with-footer-html">
					{gettingData && (
						<div className="card-body" style={{ padding: "1px" }}>
							<div
								className="spinner-grow mr-2"
								role="status"
								style={{
									color: appVariables.REACT_APP_THEME_COLOR,
								}}
							>
								<span className="sr-only">Loading...</span>
							</div>
							<div
								className="spinner-grow mr-2 ml-2"
								role="status"
								style={{
									color: appVariables.REACT_APP_THEME_COLOR,
								}}
							>
								<span className="sr-only">Loading...</span>
							</div>
							<div
								className="spinner-grow"
								role="status"
								style={{
									color: appVariables.REACT_APP_THEME_COLOR,
								}}
							>
								<span className="sr-only">Loading...</span>
							</div>
						</div>
					)}
					<div className="row">
						{getData.data?.formData.map((data, dataIndex) => (
							<div key={dataIndex} className="col-md-12">
								<div
									className="card b-all p-1 m-1"
									style={{ marginBottom: "1px" }}
								>
									<div className="card-body" style={{ padding: "1px" }}>
										{findAttendance(
											data,
											appState.dependeciesFormAPIS?.attendance?.formData
										)}
									</div>
								</div>
							</div>
						))}
					</div>
				</div>
			</div>
		);
	} catch (err) {
		return (
			<E500
				primaryColor={primaryColor}
				secondaryColor={secondaryColor}
				favicon={favicon}
				error={err}
			/>
		);
	}
};
