import React from "react";
import "../../assets/themes/t1.css";

export const T1Contact1 = ({ pageData }) => {
	return (
		<section className="t1-contact-us t1-section">
			<div className="container">
				<div className="t1-inner">
					<div className="row">
						<div className="col-lg-6">
							<div className="t1-contact-us-left">
								<iframe
									title="contactMap"
									src={pageData.content.content.mapUrl}
									width="100%"
									height={pageData.content.content.mapHeight}
									frameBorder={0}
									style={{ border: "0" }}
								/>
							</div>
						</div>
						<div className="col-lg-6">
							<div className="t1-contact-us-form">
								<h2>{pageData.content.content.header}</h2>
								<p>
									{pageData.content.content.text}
									{/* If you have any questions please fell free to contact with us. */}
								</p>
								<form className="t1-form" method="post" action="#">
									<div className="row">
										<div className="col-md-12">
											<div className="t1-form-group">
												<input
													type="text"
													name="subject"
													placeholder="Subject"
													required
												/>
											</div>
										</div>
										<div className="col-lg-12">
											<div className="t1-form-group">
												<textarea
													name="message"
													placeholder="Your Message Here..."
													required
													defaultValue={""}
												/>
											</div>
										</div>
										<div className="col-12">
											<div className="t1-form-group t1-login-btn">
												<button className="t1-btn" type="submit">
													Send
												</button>
											</div>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
				<div className="t1-contact-info">
					<div className="row">
						<div className="col-lg-4 col-12">
							<div className="t1-single-info">
								<i className="fa fa-phone" />
								<div className="t1-content">
									<h3>{pageData.content.content.phone}</h3>
								</div>
							</div>
						</div>
						<div className="col-lg-4 col-12">
							<div className="t1-single-info">
								<i className="fa fa-map-marker" />
								<div className="t1-content">
									<h3>{pageData.content.content.address}</h3>
								</div>
							</div>
						</div>
						<div className="col-lg-4 col-12">
							<div className="t1-single-info">
								<i className="fa fa-envelope" />
								<div className="t1-content">
									<h3>{pageData.content.content.email}</h3>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};
