import React from "react";
import jsPDF from "jspdf";
import { renderToString } from "react-dom/server";
import { useForm } from "react-hook-form";
import * as Y from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { useGetCall } from "../../lib/api/get";
import { useCreateCall } from "../../lib/api/create";
import httpParams from "../../lib/httpParams";
import { E500 } from "../../components/e500";

export const AttendanceModal = ({
	appVariables,
	setOpenActivity,
	activityAttenInd,
	pdfSchema,
	pdfSchemaSettings,
	schema,
	selectedApplData,
	primaryColor,
	secondaryColor,
	closeActivityModal,
	favicon,
	setActivityAttenInd,
}) => {
	const params = httpParams.adminOrgParams(appVariables);
	const createCallMutation = useCreateCall();
	const [isChildAdd, setIsChildAdd] = React.useState(false);
	const [isFileAdd, setIsFileAdd] = React.useState(false);
	const [activeActivityId, setActiveActivityId] = React.useState(null);
	const getData = useGetCall(
		appVariables.REACT_APP_API_URL,
		"activity",
		params + "&attendanceId=" + activityAttenInd
	);

	const validation = Y.object().shape({
		document: Y.mixed().required("Choose a document to upload!"),
	});

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		mode: "onSubmit",
		resolver: yupResolver(validation),
	});

	const onFormSubmit = async (data) => {
		let formData = new FormData();
		formData.append("file", data.document[0]);
		formData.append("activityId", activeActivityId);
		formData.append("activityDetailLabel", data.activityDetailLabel);
		formData.append("activityDetailNotes", data.activityDetailNotes);
		await createCallMutation.mutateAsync({
			url: appVariables.REACT_APP_API_URL,
			apiEndpoint: "activityDetail/image",
			parentAPI: "activity",
			data: formData,
			messageTitle: "Files",
			message: "File uploaded successfully!",
			params: params,
		});
	};

	if (getData.isLoading) {
		return <div>Loading...</div>;
	}

	const PDFSchema = ({ width, visibility, id, padding, groups }) => {
		const result = getData.data?.formData[0].rowFormData.reduce(function (
			r,
			a
		) {
			r[a.activityDetailType] = r[a.activityDetailType] || [];
			r[a.activityDetailType].push(a);
			return r;
		},
		Object.create(null));

		const obs = Object.keys(result).map((key) => ({ key, value: result[key] }));

		return (
			<div
				className=""
				style={{
					color: "#000000",
					width: width,
					fontSize: "10px",
					visibility: visibility,
					...padding,
				}}
				id={id}
			>
				{obs.map((egroup, groupIndex) => {
					if (egroup.key !== "image") {
						return (
							<div key={groupIndex} className="row">
								<div className="col-md-12 text-center mb-2">
									<h5>{egroup.key}</h5>
								</div>
								<div
									className="col-md-3"
									style={{
										borderRight: "solid",
										borderRightWidth: "thin",
										borderRightColor: "#000000 !important",
										borderLeft: "solid",
										borderLeftWidth: "thin",
										borderLeftColor: "#000000 !important",
										borderTop: "solid",
										borderTopWidth: "thin",
										borderTopColor: "#000000 !important",
										borderBottom: "solid",
										borderBottomWidth: "thin",
										borderBottomColor: "#000000 !important",
										fontFamily: "Arial, Helvetica, sans-serif",
										letterSpacing: "0.005em",
										paddingBottom: "8px",
										paddingTop: "8px",
										fontWeight: "bold",
										fontSize: "0.8rem",
									}}
								>
									Label
								</div>
								<div
									className="col-md-5"
									style={{
										borderRight: "solid",
										borderRightWidth: "thin",
										borderRightColor: "#000000 !important",
										borderTop: "solid",
										borderTopWidth: "thin",
										borderTopColor: "#000000 !important",
										borderBottom: "solid",
										borderBottomWidth: "thin",
										borderBottomColor: "#000000 !important",
										fontFamily: "Arial, Helvetica, sans-serif",
										letterSpacing: "0.005em",
										paddingBottom: "8px",
										paddingTop: "8px",
										fontWeight: "bold",
										fontSize: "0.8rem",
									}}
								>
									Description
								</div>
								<div
									className="col-md-4"
									style={{
										borderRight: "solid",
										borderRightWidth: "thin",
										borderRightColor: "#000000 !important",
										borderTop: "solid",
										borderTopWidth: "thin",
										borderTopColor: "#000000 !important",
										borderBottom: "solid",
										borderBottomWidth: "thin",
										borderBottomColor: "#000000 !important",
										fontFamily: "Arial, Helvetica, sans-serif",
										letterSpacing: "0.005em",
										paddingBottom: "8px",
										paddingTop: "8px",
										fontWeight: "bold",
										fontSize: "0.8rem",
									}}
								>
									Notes
								</div>
								{egroup.value.map(
									(act, actIndex) =>
										act.activityDetailDescription !== null && (
											<>
												<div
													key={actIndex}
													className="col-md-3"
													style={{
														borderRight: "solid",
														borderRightWidth: "thin",
														borderRightColor: "#000000 !important",
														borderLeft: "solid",
														borderLeftWidth: "thin",
														borderLeftColor: "#000000 !important",
														borderTop: "solid",
														borderTopWidth: "thin",
														borderTopColor: "#000000 !important",
														borderBottom: "solid",
														borderBottomWidth: "thin",
														borderBottomColor: "#000000 !important",
														fontFamily: "Arial, Helvetica, sans-serif",
														letterSpacing: "0.005em",
														paddingBottom: "2px",
														paddingTop: "2px",
														fontSize: "0.75rem",
													}}
												>
													{act.activityDetailLabel}
												</div>
												<div
													key={actIndex}
													className="col-md-5"
													style={{
														borderRight: "solid",
														borderRightWidth: "thin",
														borderRightColor: "#000000 !important",
														borderTop: "solid",
														borderTopWidth: "thin",
														borderTopColor: "#000000 !important",
														borderBottom: "solid",
														borderBottomWidth: "thin",
														borderBottomColor: "#000000 !important",
														fontFamily: "Arial, Helvetica, sans-serif",
														letterSpacing: "0.005em",
														paddingBottom: "2px",
														paddingTop: "2px",
														fontSize: "0.75rem",
													}}
												>
													{act.activityDetailDescription}
												</div>
												<div
													key={actIndex}
													className="col-md-4"
													style={{
														borderRight: "solid",
														borderRightWidth: "thin",
														borderRightColor: "#000000 !important",
														borderTop: "solid",
														borderTopWidth: "thin",
														borderTopColor: "#000000 !important",
														borderBottom: "solid",
														borderBottomWidth: "thin",
														borderBottomColor: "#000000 !important",
														fontFamily: "Arial, Helvetica, sans-serif",
														letterSpacing: "0.005em",
														paddingBottom: "2px",
														paddingTop: "2px",
														fontSize: "0.75rem",
													}}
												>
													{act.activityDetailNotes}
												</div>
											</>
										)
								)}
							</div>
						);
					} else {
						return <div></div>;
					}
				})}
			</div>
		);
	};

	const generatePDF = () => {
		const pdf = new jsPDF("p", "pt", "a4", true);
		pdf.setFont("times");
		pdf.setFontSize(8);

		const string = (
			<PDFSchema width="147mm" visibility="visible" id="toPrint" />
		);

		pdf.html(renderToString(string), {
			callback: function (doc) {
				doc.save(pdfSchemaSettings.pdfName);
			},
			x: 20,
			y: 20,
		});
	};

	try {
		return (
			<div
				className={`modal fade bd-example-modal-lg show`}
				style={{ display: "block", backgroundColor: "rgba(55,55,55,0.3)" }}
			>
				<div className="modal-dialog modal-dialog-centered modal-md">
					<div className="modal-content">
						<div className="modal-body">
							<button
								type="button"
								className="close"
								data-dismiss="modal"
								aria-label="Close"
								onClick={() => {
									setOpenActivity(false);
									setActivityAttenInd(null);
								}}
							>
								<span aria-hidden="true">×</span>
							</button>
							<div className="row">
								<div className="col-12">
									<div className="modal-bg">
										<h2 className="font-light m-b-0 mb-2">
											Activity{" "}
											<button
												onClick={() => generatePDF()}
												className="btn btn-primary btn-sm float-right"
											>
												<i className="fa fa-file-pdf-o" /> Download PDF
											</button>
										</h2>{" "}
										<hr />
										<div
											className="cScrollBar"
											style={{ maxHeight: "25rem", overflowY: "initial" }}
										>
											<PDFSchema
												width="100%"
												groups={getData.data?.formData[0].rowFormData}
												padding={{
													paddingLeft: "15px",
													paddingRight: "15px",
													paddingTop: "10px",
												}}
												visibility="visible"
												id="toView"
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	} catch (err) {
		return (
			<E500
				primaryColor={primaryColor}
				secondaryColor={secondaryColor}
				favicon={favicon}
				error={err}
			/>
		);
	}
};
