import React from "react";
import axios from "axios";
import { default as dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

import { E500 } from "../../components/e500";
import httpParams from "../../lib/httpParams";
import { queryClient } from "../../lib/react-query/react-query";
import { useGetMutateCall } from "../../lib/api/get";
import { Link } from "react-router-dom";
import { ReceiptView } from "./receiptView";

dayjs.extend(utc);
dayjs.extend(timezone);
const date = new Date();
const tzOffset = date.getTimezoneOffset();

export const Receipts = ({
	primaryColor,
	secondaryColor,
	favicon,
	appVariables,
	getData,
	authData,
}) => {
	const params = httpParams.adminOrgParams(appVariables);
	const orgDetails = localStorage.getItem("authent")
		? JSON.parse(localStorage.getItem("authent")).state.dependeciesFormAPIS?.org
				?.formData[0]
		: null;

	const [currentPage, setCurrentPage] = React.useState(1);
	const [selectedAppl, setSelectedAppl] = React.useState(null);
	const [dates, setDates] = React.useState(null);
	const [classes, setClasses] = React.useState(null);
	const [gettingData, setGettingData] = React.useState(false);
	const [selectedApplData, setSelectedApplData] = React.useState({
		data: { formData: [] },
	});

	const getCallMutation = useGetMutateCall();

	const handlePage = (index) => {
		setCurrentPage(index);
	};

	const getOnchangeAppl = async (e) => {
		setGettingData(true);
		await getCallMutation
			.mutateAsync({
				apiURL: appVariables.REACT_APP_API_URL,
				apiEndpoint: "FeeReceipt",
				params:
					params +
					"&applicationId=" +
					e +
					"&checkInDateTime=" +
					dayjs.utc(dates).format("YYYY-MM-DD HH:mm:ss") +
					"&className=" +
					classes,
			})
			.then((resp) => {
				setSelectedApplData(resp.formData);
				if (resp.formData.length <= 0) {
					setSelectedAppl(null);
				}
			});
		setGettingData(false);
	};

	try {
		return (
			<div className="contentbar">
				<div className="row">
					<div className="col-12 form-group">
						<select
							className="form-control"
							onChange={(e) => {
								getOnchangeAppl(e.target.value);
							}}
						>
							<option>Select Application</option>
							{authData.dependeciesFormAPIS["appl"]?.formData.length > 0 &&
								authData.dependeciesFormAPIS["appl"]?.formData.map(
									(ops, opsInd) => (
										<option key={opsInd} value={ops.applicationId}>
											{ops.applicationName}
										</option>
									)
								)}
						</select>
						<hr />
					</div>
					<div className="col-12">
						<div className="table-responsive">
							<table className="table table-borderless mb-2">
								<tbody>
									{selectedApplData.length > 0 ? (
										selectedApplData.map((atten, attenInd) => (
											<tr key={attenInd}>
												<td>
													<h5 className="card-title font-16 mb-1">
														{atten.Application.applicationName}
													</h5>
													<p className="text-muted font-14 mb-0">
														{dayjs(atten.feeReceivedDate).format("MMM D, YYYY")}
													</p>
												</td>
												<td className="text-right">
													<button
														className="btn btn-sm btn-primary-rgba"
														onClick={() => setSelectedAppl(atten)}
													>
														<i className="fa fa-eye"></i> View
													</button>
												</td>
											</tr>
										))
									) : (
										<tr>
											<td>No Records Found!</td>
										</tr>
									)}
								</tbody>
							</table>
						</div>
					</div>
				</div>
				{gettingData && (
					<div className="row">
						<div className="calendar-container">
							<div className="calendar">
								<div className="year-header">
									<span className="year" id="label">
										Loading...
									</span>
								</div>
							</div>
						</div>
					</div>
				)}
				{selectedAppl !== null && (
					<ReceiptView
						appVariables={appVariables}
						orgDetails={orgDetails}
						selectedAppl={selectedAppl}
						primaryColor={primaryColor}
						secondaryColor={secondaryColor}
						favicon={favicon}
					/>
				)}
			</div>
		);
	} catch (err) {
		return (
			<E500
				primaryColor={primaryColor}
				secondaryColor={secondaryColor}
				favicon={favicon}
				error={err}
			/>
		);
	}
};
