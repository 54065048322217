import React from "react";
import logo from "../assets/images/logo.png";
import { Link } from "react-router-dom";
import { E500 } from "../components/e500";

export const TopBar = ({
	setTopBarMenu,
	setSideMenu,
	setSideMenuMobile,
	openModal,
	setLoginModal,
	setUserRoles,
	userLogout,
	authSession,
	primaryColor,
	secondaryColor,
	favicon,
	appVariables,
	setIsSideMenuLarge,
	isSideMenuLarge,
}) => {
	try {
		return (
			<>
				<div className="topbar-mobile">
					<div className="row align-items-center">
						<div className="col-md-12">
							<div
								className="mobile-logobar"
								style={{ display: "inline-flex" }}
							>
								<Link to="/" className="mobile-logo">
									<img
										src={appVariables.REACT_APP_THEME_LOGO}
										className="img-fluid"
										alt="logo"
										style={{ width: "60%" }}
									/>
								</Link>
							</div>
							<div className="mobile-togglebar">
								<ul className="list-inline mb-0">
									<li className="list-inline-item">
										<div className="topbar-toggle-icon">
											<a
												className="topbar-toggle-hamburger cursor_Pointer"
												href
											>
												<img
													src={
														require("../assets/images/svg-icon/horizontal.svg")
															.default
													}
													id="tbMenuOpen"
													onClick={() => setTopBarMenu("open")}
													className="img-fluid menu-hamburger-horizontal"
													alt="horizontal"
												/>
												<img
													src={
														require("../assets/images/svg-icon/verticle.svg")
															.default
													}
													id="tbMenuClose"
													onClick={() => setTopBarMenu("close")}
													className="img-fluid menu-hamburger-vertical"
													alt="verticle"
												/>
											</a>
										</div>
									</li>
									<li className="list-inline-item">
										<div className="menubar">
											<span className="menu-hamburger cursor_Pointer">
												<img
													src={
														require("../assets/images/svg-icon/collapse.svg")
															.default
													}
													id="mhcollapseLB"
													onClick={() => setSideMenu("open")}
													className="img-fluid menu-hamburger-collapse"
													alt="collapse"
												/>
												<img
													src={
														require("../assets/images/svg-icon/close.svg")
															.default
													}
													id="mhcloseLB"
													onClick={() => setSideMenu("close")}
													className="img-fluid menu-hamburger-close"
													alt="close"
												/>
											</span>
										</div>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				<div className="topbar">
					<div className="row align-items-center">
						<div className="col-md-12 align-self-center">
							<div className="togglebar">
								<ul className="list-inline mb-0">
									<li className="list-inline-item">
										<div className="menubar">
											<span className="menu-hamburger cursor_Pointer">
												<img
													src={
														require("../assets/images/svg-icon/collapse.svg")
															.default
													}
													id="mhcollapseLBMobile"
													onClick={() => {
														setSideMenuMobile("open");
														setIsSideMenuLarge(!isSideMenuLarge);
													}}
													className="img-fluid menu-hamburger-collapse"
													alt="collapse"
												/>
												<img
													src={
														require("../assets/images/svg-icon/close.svg")
															.default
													}
													id="mhcloseLBMobile"
													onClick={() => {
														setSideMenuMobile("close");
														setIsSideMenuLarge(!isSideMenuLarge);
													}}
													className="img-fluid menu-hamburger-close"
													alt="close"
												/>
											</span>
										</div>
									</li>
								</ul>
							</div>
							<div className="infobar">
								<ul className="list-inline mb-0">
									<li className="list-inline-item">
										<div className="notifybar">
											<Link to="/" className="infobar-icon">
												<img
													src={
														require("../assets/images/svg-icon/home.svg")
															.default
													}
													className="img-fluid"
													alt="notifications"
												/>
											</Link>
										</div>
									</li>
									{authSession.session?.user?.userRoles?.length > 1 && (
										<li className="list-inline-item">
											<div className="settingbar">
												<span
													id="infobar-settings-open"
													className="infobar-icon cursor_Pointer"
												>
													<img
														src={
															require("../assets/images/svg-icon/roles.svg")
																.default
														}
														onClick={() => setUserRoles(true)}
														className="img-fluid"
														alt="settings"
													/>
												</span>
											</div>
										</li>
									)}
									<li className="list-inline-item">
										<div className="settingbar">
											<span
												id="infobar-settings-open"
												onClick={() => userLogout()}
												className="infobar-icon cursor_Pointer"
											>
												<img
													src={
														require("../assets/images/svg-icon/logout.svg")
															.default
													}
													className="img-fluid"
													alt="settings"
												/>
											</span>
										</div>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	} catch (err) {
		return (
			<E500
				primaryColor={primaryColor}
				secondaryColor={secondaryColor}
				favicon={favicon}
				error={err}
			/>
		);
	}
};
