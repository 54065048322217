import React from "react";
export const Notification = ({
	notification: { id, type, title, message },
	onDismiss,
}) => {
	const [openMessage, setOpenMessage] = React.useState({ 1: true });
	const closeMessage = () => {
		onDismiss();
		setOpenMessage(false);
	};
	React.useEffect(() => {
		let timer1 = setTimeout(() => closeMessage(), 5 * 1000);
		return () => {
			clearTimeout(timer1);
		};
	}, []);

	return (
		<div
			className={`toast fade ${openMessage && "show"}`}
			role="alert"
			aria-live="assertive"
			aria-atomic="true"
			style={{
				marginTop: "0.5rem",
				marginRight: "0.5rem",
				display: openMessage ? "block" : "none",
			}}
			key={id}
		>
			<div className="toast-header">
				{type === "success" && (
					<>
						<i className="feather icon-check-square text-success mr-2"></i>
						<strong className="toast-title mr-auto text-success">
							{title}
						</strong>
					</>
				)}
				{type === "error" && (
					<>
						<i className="feather icon-alert-triangle text-danger mr-2"></i>
						<strong className="toast-title mr-auto text-danger">{title}</strong>
					</>
				)}

				<button
					type="button"
					className="ml-2 mb-1 close float-right"
					data-dismiss="toast"
					onClick={() => setOpenMessage(false)}
					aria-label="Close"
				>
					<span aria-hidden="true">×</span>
				</button>
			</div>
			<div className="toast-body">{message}</div>
		</div>
	);
};
