import React from "react";
import { usePagination } from "../lib/pagination/usePagination";
import { E500 } from "../components/e500";

export const Pagination = ({
	currentPage,
	totalCount,
	siblingCount,
	pageSize,
	onPageChange,
	align,
	primaryColor,
	secondaryColor,
	favicon,
}) => {
	const paginationRange = usePagination({
		currentPage,
		totalCount,
		siblingCount,
		pageSize,
	});

	const onNext = () => {
		onPageChange(currentPage + 1);
	};

	const onPrevious = () => {
		onPageChange(currentPage - 1);
	};

	try {
		return (
			<nav aria-label="Page navigation">
				<ul className={`pagination ${align}`} style={{ float: { align } }}>
					<li
						className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
						data-testid="OnPreviousLink"
						onClick={() => currentPage > 1 && onPrevious()}
					>
						<span aria-label="Next" className="page-link cursor_Pointer">
							<i className="fa fa-angle-left" />
						</span>
					</li>
					{paginationRange.map((pageNumber, pageNumberIndex) => {
						return (
							<li
								key={pageNumberIndex}
								className={`page-item cursor_Pointer ${
									pageNumber === currentPage ? "active" : ""
								}`}
								data-testid="OnActiveLink"
								onClick={() => pageNumber !== "..." && onPageChange(pageNumber)}
							>
								<span aria-label="1" className="page-link cursor_Pointer">
									{pageNumber}
								</span>
							</li>
						);
					})}
					<li
						className={`page-item ${
							currentPage === totalCount ? "disabled" : "cursor_Pointer"
						}`}
						data-testid="OnNextLink"
						onClick={() => currentPage < totalCount && onNext()}
					>
						<span aria-label="Next" className="page-link cursor_Pointer">
							<i className="fa fa-angle-right" />
						</span>
					</li>
				</ul>
			</nav>
		);
	} catch (err) {
		return (
			<E500
				primaryColor={primaryColor}
				secondaryColor={secondaryColor}
				favicon={favicon}
				error={err}
			/>
		);
	}
};
