import React from "react";

export const Image = ({ pageData }) => {
	return (
		<div>
			<img
				src={pageData.image.image}
				className="img-fluid"
				alt={pageData.title.title}
			/>
		</div>
	);
};
