import React from "react";
import { E500 } from "./e500";

export const ConfirmModal = ({
	onDelete,
	setDeleteConfirm,
	setDeleteId,
	primaryColor,
	secondaryColor,
	favicon,
}) => {
	try {
		return (
			<div
				className="modal fade bd-example-modal-lg show"
				tabIndex={-1}
				role="dialog"
				aria-modal="true"
				style={{ display: "block", backgroundColor: "rgba(55,55,55,0.3)" }}
			>
				<div className="modal-dialog modal-dialog-centered modal-md">
					<div className="modal-content">
						<div className="modal-body">
							<div id="containerbar" className="containerbar authenticate-bg">
								<div className="container">
									<div className="auth-box login-box">
										<div className="row no-gutters align-items-center justify-content-center">
											<div className="col-md-12">
												<div className="auth-box-right">
													<div className="card">
														<div className="card-body text-center">
															<h4 className="text-danger mb-4">Delete</h4>
															<div className="login-or">
																<h6 className="text-muted">
																	Are your sure you want to delete?
																</h6>
															</div>
															<div className="row">
																<div className="col-6">
																	<button
																		type="submit"
																		className="btn btn-danger-rgba btn-lg btn-block font-18"
																		onClick={() => onDelete()}
																	>
																		Delete
																	</button>
																</div>
																<div className="col-6">
																	<button
																		type="submit"
																		className="btn btn-primary-rgba btn-lg btn-block font-18"
																		onClick={() => {
																			setDeleteConfirm(false);
																			setDeleteId(null);
																		}}
																	>
																		Cancel
																	</button>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	} catch (err) {
		return (
			<E500
				primaryColor={primaryColor}
				secondaryColor={secondaryColor}
				favicon={favicon}
				error={err}
			/>
		);
	}
};
