import React from "react";
import { E500 } from "../components/e500";

export const Title = ({
	addMainCrud,
	setMainCrudAdd,
	mainCrudAdd,
	modSchema,
	primaryColor,
	secondaryColor,
	favicon,
}) => {
	try {
		return (
			<div className="breadcrumbbar">
				<div className="row align-items-center">
					<div className="col-md-8 col-lg-8">
						<h4 className="page-title">{modSchema.title}</h4>
					</div>
					<div className="col-md-4 col-lg-4">
						{modSchema.form?.crud && modSchema.form.crud > 2 && (
							<div className="widgetbar">
								<button
									className="btn btn-sm btn-primary"
									onClick={() => addMainCrud(setMainCrudAdd)}
									disabled={mainCrudAdd}
								>
									<i className="fa fa-plus" /> Add
								</button>
							</div>
						)}
					</div>
				</div>
			</div>
		);
	} catch (err) {
		return (
			<E500
				primaryColor={primaryColor}
				secondaryColor={secondaryColor}
				favicon={favicon}
				error={err}
			/>
		);
	}
};
