import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Y from "yup";
import axios from "axios";

import { useCreateCall } from "../lib/api/create";
import { useUpdateCall } from "../lib/api/update";
import { useDeleteCall } from "../lib/api/delete";
import { useGetCall } from "../lib/api/get";
import httpParams from "../lib/httpParams";
import { createYupSchema } from "../lib/createYupSchema";
import { ConfirmModal } from "./confirmModal";
import { InputField } from "./formelements/inputField";
import { SwitchBox } from "./formelements/switchBox";
import { FileChooser } from "./formelements/fileChooser";
import { SelectBox } from "./formelements/selectBox";
import { TextArea } from "./formelements/textArea";
import { E500 } from "./e500";

export const Form = ({
	appVariables,
	defaultData,
	formSchema,
	isAdd,
	closeAddMainCrud,
	setMainCrudAdd,
	addUserObject,
	updateLocal,
	updateLocalEndpoint,
	authData,
	mainData,
	isRowForm,
	isModalForm,
	parentId,
	isChildAdd,
	setIsChildAdd,
	crud,
	prefixValue,
	primaryColor,
	secondaryColor,
	favicon,
	modSchema,
}) => {
	const params = httpParams.adminOrgParams(appVariables);
	const createCallMutation = useCreateCall();
	const updateCallMutation = useUpdateCall();
	const deleteCallMutation = useDeleteCall();

	const [deleteId, setDeleteId] = React.useState(null);
	const [deleteConfirm, setDeleteConfirm] = React.useState(false);
	const [imageFile, setImageFile] = React.useState(null);

	const validation = Y.object().shape({
		...formSchema.buildValidation.reduce(createYupSchema, {}),
	});

	const authSession =
		localStorage.getItem("authenticateSession") &&
		JSON.parse(localStorage.getItem("authenticateSession"));

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		mode: isAdd ? "onSubmit" : "onChange",
		resolver: yupResolver(validation),
	});

	const confirmDelete = (id) => {
		setDeleteId(id);
		setDeleteConfirm(true);
	};

	const onCreate = async (data, e) => {
		if (formSchema.hasFile) {
			let formData = new FormData();
			formData.append("docType", data.docType);
			formData.append("file", data.file[0]);
			formData.append("orgId", appVariables.REACT_APP_ORG_ID);
			const uID = localStorage.getItem("authenticateSession");
			formData["userId"] = authSession.session.user.userId;
			if (isRowForm || isModalForm) {
				await createCallMutation.mutateAsync({
					url: appVariables.REACT_APP_API_URL,
					apiEndpoint: formSchema.formAPI,
					parentAPI: formSchema.parentAPI,
					data: formData, //data,
					messageTitle: formSchema.message.title,
					message: formSchema.message.message,
					params: params,
					isMultipart: true,
				});
			} else {
				await createCallMutation.mutateAsync({
					url: appVariables.REACT_APP_API_URL,
					apiEndpoint: formSchema.formAPI,
					data: formData, //data,
					messageTitle: formSchema.message.title,
					message: formSchema.message.message,
					params: params,
					isMultipart: true,
				});
			}
		} else {
			const payload = { ...data };
			if (formSchema.addParentId) {
				payload[formSchema.parentId] = parentId;
			}
			if (formSchema.addUserId) {
				payload[formSchema.userId] = authSession.session.user.userId;
			}

			payload["appId"] = appVariables.REACT_APP_APP_ID;
			payload["tenantId"] = appVariables.REACT_APP_TENANT_ID;
			payload["orgId"] = appVariables.REACT_APP_ORG_ID;

			if (isRowForm || isModalForm) {
				await createCallMutation.mutateAsync({
					url: appVariables.REACT_APP_API_URL,
					apiEndpoint: formSchema.formAPI,
					parentAPI: formSchema.parentAPI,
					data: payload, //data,
					messageTitle: formSchema.message.title,
					message: formSchema.message.message,
					params: params,
				});
			} else {
				await createCallMutation.mutateAsync({
					url: appVariables.REACT_APP_API_URL,
					apiEndpoint: formSchema.formAPI,
					data: payload, //data,
					messageTitle: formSchema.message.title,
					message: formSchema.message.message,
					params: params,
				});
			}
			if (formSchema.refreshAppData) {
				axios.get(appVariables.REACT_APP_API_URL_WEB + "/updateAppData");
			}
			e.target.reset();
		}
	};

	const onUpdate = async (data) => {
		for (let prop in defaultData) {
			if (defaultData.hasOwnProperty(prop)) {
				if (!(prop in data)) {
					data[prop] = defaultData[prop];
				}
			}
		}

		const payload = { ...data };
		if (formSchema.makebool) {
			formSchema.makeboolKey.map((k) => (payload[k.k] = k.v));
		}
		payload["appId"] = appVariables.REACT_APP_APP_ID;
		payload["tenantId"] = appVariables.REACT_APP_TENANT_ID;
		payload["orgId"] = appVariables.REACT_APP_ORG_ID;

		if (formSchema.handleDatefields) {
			formSchema.dateFields.map((k) => {
				if (payload[k] === "Invalid Date") {
					payload[k] = null;
				}
			});
		}

		if (addUserObject === true) {
			const plData = authSession.session.user;

			plData["password_hash"] = payload["password_hash"];
			delete plData["UserFamily"];
			delete plData["rowFormData"];
			delete plData["userLogins"];
			delete plData["userRoles"];
			delete plData["userApplications"];
			if (formSchema.captureFingerPrint) {
				plData[formSchema.fingerPrintField] = localStorage.getItem("mfsFP");
			}

			const isLocalEnvironment =
				process.env.REACT_APP_USE_API_URL_LOCAL === "1"
					? ""
					: process.env.REACT_APP_API_URL_LOCAL;

			const isUpdateEndpoint =
				modSchema && modSchema.updateLocal && modSchema.updateLocal === true
					? { url: isLocalEnvironment, endPoint: modSchema.updateLocalEndpoint }
					: {
							url: appVariables.REACT_APP_API_URL,
							endPoint: formSchema.formAPI,
					  };

			await updateCallMutation.mutateAsync({
				url: isUpdateEndpoint.url,
				apiEndpoint: isUpdateEndpoint.endPoint,
				data: plData, // data,
				id: plData.userId,
				messageTitle: formSchema.message.title,
				message: formSchema.message.message,
				params:
					params +
					`${
						modSchema && modSchema.updateLocal && modSchema.updateLocal
							? "&endPoint=appuser&id=" + plData.userId
							: ""
					}`,
			});
			if (formSchema.refreshAppData) {
				axios.get(appVariables.REACT_APP_API_URL_WEB + "/updateAppData");
			}
		} else {
			if (formSchema.captureFingerPrint) {
				payload[formSchema.fingerPrintField] = localStorage.getItem("mfsFP");
				localStorage.removeItem("mfsFP");
			}
			const isLocalEnvironment =
				process.env.REACT_APP_USE_API_URL_LOCAL === "1"
					? ""
					: process.env.REACT_APP_API_URL_LOCAL;
			const isUpdateEndpoint =
				modSchema && modSchema.updateLocal && modSchema.updateLocal
					? { url: isLocalEnvironment, endPoint: modSchema.updateLocalEndpoint }
					: {
							url: appVariables.REACT_APP_API_URL,
							endPoint: formSchema.formAPI,
					  };

			await updateCallMutation.mutateAsync({
				url: isUpdateEndpoint.url,
				apiEndpoint: isUpdateEndpoint.endPoint,
				data: payload, // data,
				id: payload[formSchema.id],
				messageTitle: formSchema.message.title,
				message: formSchema.message.message,
				params:
					params +
					`${
						modSchema && modSchema.updateLocal && modSchema.updateLocal
							? "&endPoint=appuser&id=" + payload[formSchema.id]
							: ""
					}`,
			});
			if (formSchema.refreshAppData) {
				axios.get(appVariables.REACT_APP_API_URL_WEB + "/updateAppData");
			}
		}
	};

	const onDelete = async () => {
		if (isRowForm) {
			await deleteCallMutation.mutateAsync({
				url: appVariables.REACT_APP_API_URL,
				apiEndpoint: formSchema.formAPI,
				parentAPI: formSchema.parentAPI,
				id: deleteId,
				messageTitle: formSchema.deleteMessage.title,
				message: formSchema.deleteMessage.message,
				params,
			});
		} else {
			await deleteCallMutation.mutateAsync({
				url: appVariables.REACT_APP_API_URL,
				apiEndpoint: formSchema.formAPI,
				id: deleteId,
				messageTitle: formSchema.deleteMessage.title,
				message: formSchema.deleteMessage.message,
				params,
			});
		}
		if (formSchema.refreshAppData) {
			axios.get(appVariables.REACT_APP_API_URL_WEB + "/updateAppData");
		}
		setDeleteConfirm(false);
	};

	try {
		return (
			<React.Fragment key={`${isAdd}${formSchema}`}>
				<form
					onSubmit={handleSubmit(isAdd ? onCreate : onUpdate)}
					data-testid="formAdd"
				>
					<div className="form-row">
						{formSchema.schema.length > 0 &&
							formSchema.schema.map((field, fieldIndex) =>
								field.element === "InputField" ? (
									<InputField
										key={`${fieldIndex}${field}${isAdd}`}
										field={field}
										errors={errors}
										register={register}
										isAdd={isAdd}
										defaultData={defaultData}
										prefixValue={prefixValue}
										primaryColor={primaryColor}
										secondaryColor={secondaryColor}
										favicon={favicon}
									/>
								) : field.element === "SwitchBox" ? (
									<SwitchBox
										key={fieldIndex}
										field={field}
										errors={errors}
										register={register}
										isAdd={isAdd}
										defaultData={defaultData}
										primaryColor={primaryColor}
										secondaryColor={secondaryColor}
										favicon={favicon}
									/>
								) : field.element === "FileChooser" ? (
									<FileChooser
										key={fieldIndex}
										field={field}
										errors={errors}
										register={register}
										isAdd={isAdd}
										defaultData={defaultData}
										setImageFile={setImageFile}
										primaryColor={primaryColor}
										secondaryColor={secondaryColor}
										favicon={favicon}
									/>
								) : field.element === "SelectBox" ? (
									<SelectBox
										key={fieldIndex}
										field={field}
										errors={errors}
										register={register}
										isAdd={isAdd}
										defaultData={defaultData}
										setImageFile={setImageFile}
										mainData={mainData}
										primaryColor={primaryColor}
										secondaryColor={secondaryColor}
										favicon={favicon}
									/>
								) : field.element === "TextArea" ? (
									<TextArea
										key={fieldIndex}
										field={field}
										errors={errors}
										register={register}
										isAdd={isAdd}
										defaultData={defaultData}
										setImageFile={setImageFile}
										mainData={mainData}
										primaryColor={primaryColor}
										secondaryColor={secondaryColor}
										favicon={favicon}
									/>
								) : (
									<InputField
										key={fieldIndex}
										field={field}
										errors={errors}
										register={register}
										isAdd={isAdd}
										defaultData={defaultData}
										primaryColor={primaryColor}
										secondaryColor={secondaryColor}
										favicon={favicon}
									/>
								)
							)}

						{isRowForm && (
							<div className="form-group col-auto">
								<label
									htmlFor="inputEmail4"
									style={{ color: "transparent", userSelect: "none" }}
								>
									Action
								</label>
								<br />
								{crud > 1 && formSchema.noUpdate !== true && (
									<>
										<button
											type="submit"
											className="btn btn-success-rgba btn-sm"
										>
											{(isChildAdd && createCallMutation.isLoading) ||
											updateCallMutation.isLoading ? (
												<>
													<div
														className="spinner-border"
														style={{
															height: "1rem",
															width: "1rem",
															border: "0.15em solid currentColor",
															borderRightColor: "transparent",
															marginBottom: "0.2rem",
														}}
														role="status"
													>
														<span className="sr-only">Loading...</span>
													</div>{" "}
												</>
											) : (
												<i className="fa fa-check" />
											)}
										</button>{" "}
									</>
								)}
								{isChildAdd && crud > 3 ? (
									<button
										type="button"
										className="btn btn-secondary-rgba btn-sm"
										onClick={() => setIsChildAdd(false)}
									>
										{deleteCallMutation.isLoading ? (
											<>
												<div
													className="spinner-border"
													style={{
														height: "1rem",
														width: "1rem",
														border: "0.15em solid currentColor",
														borderRightColor: "transparent",
														marginBottom: "0.2rem",
													}}
													role="status"
												>
													<span className="sr-only">Loading...</span>
												</div>{" "}
											</>
										) : (
											<i className="fa fa-times" />
										)}
									</button>
								) : (
									<button
										type="button"
										className="btn btn-danger-rgba btn-sm"
										onClick={() => confirmDelete(defaultData[formSchema.id])}
									>
										{deleteCallMutation.isLoading ? (
											<>
												<div
													className="spinner-border"
													style={{
														height: "1rem",
														width: "1rem",
														border: "0.15em solid currentColor",
														borderRightColor: "transparent",
														marginBottom: "0.2rem",
													}}
													role="status"
												>
													<span className="sr-only">Loading...</span>
												</div>{" "}
											</>
										) : (
											<i className="fa fa-trash" />
										)}
									</button>
								)}
							</div>
						)}
					</div>
					{isAdd
						? isRowForm !== true && (
								<>
									<button
										type="submit"
										className="btn btn-success"
										disabled={createCallMutation.isLoading}
									>
										{createCallMutation.isLoading && (
											<>
												<div
													className="spinner-border"
													style={{
														height: "1rem",
														width: "1rem",
														border: "0.15em solid currentColor",
														borderRightColor: "transparent",
														marginBottom: "0.2rem",
													}}
													role="status"
												>
													<span className="sr-only">Loading...</span>
												</div>{" "}
											</>
										)}
										Add
									</button>
									<button
										type="button"
										onClick={() => {
											closeAddMainCrud(setMainCrudAdd);
											setMainCrudAdd(false);
										}}
										className="btn btn-secondary ml-2"
									>
										Close
									</button>
								</>
						  )
						: isRowForm !== true && (
								<>
									<button
										type="submit"
										className="btn btn-success"
										disabled={updateCallMutation.isLoading}
									>
										{updateCallMutation.isLoading ? (
											<>
												<div
													className="spinner-border"
													style={{
														height: "1rem",
														width: "1rem",
														border: "0.15em solid currentColor",
														borderRightColor: "transparent",
														marginBottom: "0.2rem",
													}}
													role="status"
												>
													<span className="sr-only">Loading...</span>
												</div>{" "}
												{formSchema.loadingButtonName}
											</>
										) : (
											<>{formSchema.updateButtonName}</>
										)}
									</button>
									{crud > 3 && (
										<button
											type="button"
											onClick={() => confirmDelete(defaultData[formSchema.id])}
											className="btn btn-danger ml-2"
											disabled={deleteCallMutation.isLoading}
										>
											{deleteCallMutation.isLoading && (
												<>
													<div
														className="spinner-border"
														style={{
															height: "1rem",
															width: "1rem",
															border: "0.15em solid currentColor",
															borderRightColor: "transparent",
															marginBottom: "0.2rem",
														}}
														role="status"
													>
														<span className="sr-only">Loading...</span>
													</div>{" "}
												</>
											)}
											Delete
										</button>
									)}
								</>
						  )}
				</form>
				{deleteConfirm && (
					<ConfirmModal
						primaryColor={primaryColor}
						secondaryColor={secondaryColor}
						favicon={favicon}
						onDelete={onDelete}
						setDeleteConfirm={setDeleteConfirm}
						setDeleteId={setDeleteId}
					/>
				)}
			</React.Fragment>
		);
	} catch (err) {
		return (
			<E500
				primaryColor={primaryColor}
				secondaryColor={secondaryColor}
				favicon={favicon}
				error={err}
			/>
		);
	}
};
