import React from "react";
import { default as dayjs } from "dayjs";
import { E500 } from "../../components/e500";

export const AttendanceCompAttendance = ({
	selectedApplData,
	schema,
	daysInThisMonth,
	primaryColor,
	secondaryColor,
	favicon,
	setActivityAttenInd,
	setOpenActivity,
}) => {
	try {
		return (
			<div id="header-with-footer-html" className="row">
				<div className="col-12">
					<div className="calendar-container">
						<div className="calendar">
							<div className="year-header">
								<span
									className="year"
									id="label"
									style={{ textTransform: "uppercase" }}
								>
									{dayjs(daysInThisMonth(selectedApplData)[1][0].date).format(
										"MMM"
									)}{" "}
									-{" "}
									{dayjs(daysInThisMonth(selectedApplData)[1][0].date).format(
										"YYYY"
									)}
								</span>
							</div>
							<table className="days-table w-100">
								<tbody>
									<tr>
										<td className="day">Sun</td>
										<td className="day">Mon</td>
										<td className="day">Tue</td>
										<td className="day">Wed</td>
										<td className="day">Thu</td>
										<td className="day">Fri</td>
										<td className="day">Sat</td>
									</tr>
									<tr className="table-row" />
									{daysInThisMonth(selectedApplData).length > 0 &&
										daysInThisMonth(selectedApplData).map(
											(dayChunk, dayChunkIndex) => (
												<tr key={dayChunkIndex} className="table-row">
													{dayChunk.length > 0 &&
														dayChunk.map((day, dayIndex) =>
															day.isEmpty && day.isEmpty === true ? (
																<td
																	key={`${dayChunkIndex}${dayIndex}`}
																	className="table-date nil"
																/>
															) : (
																<>
																	<td
																		key={`${dayChunkIndex}${dayIndex}`}
																		className={`table-date ${
																			dayjs(day.date).format("DD-MM-YYYY") <
																				dayjs(new Date()).format(
																					"DD-MM-YYYY"
																				) && day.weekdays[day.day] !== "Sun"
																				? day.ateendance === 5 ||
																				  day.ateendance === 0
																					? "text-success"
																					: day.ateendance === 7
																					? "text-warning"
																					: day.ateendance === null
																					? ""
																					: "text-danger"
																				: ""
																		} ${
																			dayjs(day.date).format("DD-MM-YYYY") ===
																				dayjs(new Date()).format(
																					"DD-MM-YYYY"
																				) && day.weekdays[day.day] !== "Sun"
																				? day.ateendance === 5 ||
																				  day.ateendance === 0
																					? "bg-success text-white active-date-width"
																					: day.ateendance === 7
																					? "bg-warning text-white active-date-width"
																					: day.ateendance === null
																					? ""
																					: "bg-danger text-white active-date-width"
																				: ""
																		}`}
																		title={
																			dayjs(day.date).format("DD-MM-YYYY") <=
																				dayjs(new Date()).format(
																					"DD-MM-YYYY"
																				) && day.weekdays[day.day] !== "Sun"
																				? day.ateendance === 5 ||
																				  day.ateendance === 0
																					? "Present"
																					: day.ateendance === 7
																					? "Leave"
																					: day.ateendance === null
																					? ""
																					: "Absent"
																				: ""
																		}
																	>
																		{day.attendanceData.attendanceId ===
																			undefined ||
																		day.attendanceData.attendanceId === null ? (
																			<span className="">
																				{dayjs(day.date).format("DD")}
																			</span>
																		) : (
																			<span
																				className="cursor_Pointer text-success"
																				onClick={() => {
																					setOpenActivity(true);
																					setActivityAttenInd(
																						day.attendanceData.attendanceId
																					);
																				}}
																			>
																				{dayjs(day.date).format("DD")}
																			</span>
																		)}
																	</td>
																</>
															)
														)}
												</tr>
											)
										)}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		);
	} catch (err) {
		return (
			<E500
				primaryColor={primaryColor}
				secondaryColor={secondaryColor}
				favicon={favicon}
				error={err}
			/>
		);
	}
};
