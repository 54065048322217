import React from "react";

import { mainUILoad, closeModal } from "../lib/uihelper";
import logo from "../assets/images/logo.png";
import "../assets/css/bootstrap.min.css";
import "../assets/css/icons.css";
import "../assets/css/flag-icon.min.css";
import "../assets/css/style.css";
import "../assets/css/custom.css";
export const E500 = (props) => {
	if (props.primaryColor && props.secondaryColor && props.favicon) {
		mainUILoad(props.primaryColor, props.secondaryColor, props.favicon);
	}
	console.log(props.error);
	return (
		<div>
			{/* Start Containerbar */}
			<div id="containerbar" className="containerbar authenticate-bg">
				{/* Start Container */}
				<div className="container">
					<div className="auth-box error-box">
						{/* Start row */}
						<div className="row no-gutters align-items-center justify-content-center">
							{/* Start col */}
							<div className="col-md-8 col-lg-6">
								<div className="text-center">
									<img src={logo} className="img-fluid error-logo" alt="logo" />
									<img
										src={
											require("../assets/images/error/internal-server.svg")
												.default
										}
										className="img-fluid error-image w-75"
										alt={500}
									/>
									<h4 className="error-subtitle mb-4">
										500 Internal Server Error
									</h4>
									<p className="mb-4">
										The server encountered an internal error and was unable to
										complete your request. Please try again{" "}
									</p>
									<button
										onClick={() => window.location.reload()}
										className="btn btn-primary font-16"
									>
										<i className="feather icon-alert-triangle mr-2" /> Try Again
									</button>
								</div>
							</div>
							{/* End col */}
						</div>
						{/* End row */}
					</div>
				</div>
				{/* End Container */}
			</div>
			{/* End Containerbar */}
		</div>
	);
};
